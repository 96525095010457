import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { LIST_HOLIDAYS } from 'app/const/api/V2';
import { clientQuery } from 'common/utils/ApiUtils';
import { handleConvertHoliday } from './ultil/HolidayUtil';

const ServicesHoliday = ({ isMonthView = false, onUpdateHoliday = () => {} }) => {
    const { start, end } = useSelector(({ inlineCalendarReducer }) => inlineCalendarReducer);
    const schedules = useSelector(({ calendar }) => calendar.schedules);

    useEffect(() => {
        if (start && end) getHolidays({ start, end });
    }, [start]);

    const getHolidays = ({ start = '', end = '' } = {}) => {
        const handleGetSuccess = ({ data }) => {
            const dataHolidayEvents = handleConvertHoliday({ data, schedules, isMonthView });
            onUpdateHoliday(dataHolidayEvents);
        };
        const handleGetFail = () => {
            onUpdateHoliday([]);
        };
        clientQuery(LIST_HOLIDAYS, { data: { start, end }, method: 'GET' }, handleGetSuccess, handleGetFail);
    };
    return null;
};

export default ServicesHoliday;
