import { COMMON, LIST_TABLE_ITEM_TYPE } from '../App';
import IconDoubleCheck from 'assets/icon/IconDoubleCheck';
import IconTime from 'assets/icon/IconTime';
import IconTrash from 'assets/icon/IconTrash';
import i18n from 'assets/i18n';
import { mapCurrentValueHeader } from 'common/utils/GridViewUtils';

export const TAB = {
    LIST: [
        { id: 'active', name: 'active', value: 'active' },
        { id: 'archived', name: 'archived', value: 'archived' },
        { id: 'deleted', name: 'deleted', value: 'deleted' }
    ],
    DEFAULT_SELECTED: {
        ACTIVE: 'active',
        ARCHIVE: 'archived',
        DELETE: 'deleted'
    }
};

const BUTTON_ACTION_ACTIVE = {
    id: 1,
    label: 'active',
    className: 'v2-btn-default --grey has-icon svg-delete-grey btn-modal header-items',
    iconClassName: 'icon outline-timer',
    value: 1,
    iconSvg: <IconDoubleCheck />
};

const BUTTON_ACTION_ARCHIVE = {
    id: 2,
    label: 'archive',
    className: 'v2-btn-default --grey has-icon svg-delete-grey btn-modal header-items',
    value: 2,
    iconSvg: <IconTime />
};

const BUTTON_ACTION_UNDELETE = {
    id: 3,
    label: 'un_delete',
    className: 'v2-btn-default --grey has-icon svg-delete-grey btn-modal header-items',
    value: 3,
    iconSvg: <IconDoubleCheck />
};

const BUTTON_ACTION_DELETE = {
    id: 4,
    label: 'delete',
    className: 'v2-btn-default --grey has-icon svg-delete-grey btn-modal header-items',
    iconClassName: 'svg-delete-grey',
    iconSvg: <IconTrash />,
    value: 4
};
export const BUTTON_ACTION_PERMANENTLY_DELETE = {
    id: 5,
    label: 'permanently_delete',
    className: 'v2-btn-default --grey has-icon svg-delete-grey btn-modal header-items',
    iconClassName: 'svg-delete-grey',
    iconSvg: <IconTrash />,
    value: 5
};

export const LIST_BUTTONS = {
    active: [BUTTON_ACTION_DELETE, BUTTON_ACTION_ARCHIVE],
    archived: [BUTTON_ACTION_ACTIVE, BUTTON_ACTION_DELETE],
    deleted: [BUTTON_ACTION_UNDELETE, BUTTON_ACTION_ARCHIVE, BUTTON_ACTION_PERMANENTLY_DELETE]
};

export const LIST_BUTTON_MESSAGES = {
    [BUTTON_ACTION_ACTIVE.id]: i18n.t('common:are_you_sure_active_this_item'),
    [BUTTON_ACTION_ARCHIVE.id]: i18n.t('common:are_you_sure_archive_this_item'),
    [BUTTON_ACTION_DELETE.id]: i18n.t('common:are_you_sure_delete_this_item'),
    [BUTTON_ACTION_UNDELETE.id]: i18n.t('common:are_you_sure_undelete_this_item'),
    [BUTTON_ACTION_PERMANENTLY_DELETE.id]: i18n.t('setting:permanently_delete_confirm')
};

const header = {
    name: {
        title: 'service name',
        style: 'col',
        actionType: COMMON.ORDER,
        currentValue: COMMON.NOT_SET
    },
    length: {
        title: 'default job length',
        style: 'col col-md-plus',
        actionType: COMMON.ORDER,
        currentValue: COMMON.NOT_SET
    },
    color_code: {
        title: 'color code',
        style: 'col --colorcode col-md-plus'
    },
    frequency: {
        title: 'frequency',
        style: 'col --frequence',
        actionType: COMMON.ORDER,
        currentValue: COMMON.NOT_SET
    }
};
const columns = [
    {
        id: 'name',
        title: 'name',
        status: true
    },
    {
        id: 'length',
        title: 'length',
        status: true
    },
    {
        id: 'color_code',
        title: 'color_code',
        status: true
    },
    {
        id: 'frequency',
        title: 'frequency',
        status: true
    }
];
const contentConfig = {
    name: {
        title: 'name',
        style: 'col --name',
        type: LIST_TABLE_ITEM_TYPE.SERVICE_NAME
    },
    length: {
        title: 'length',
        style: 'col col-md-plus',
        type: LIST_TABLE_ITEM_TYPE.DEFAULT
    },
    color_code: {
        title: 'color code',
        style: 'col --colorcode col-md-plus',
        type: LIST_TABLE_ITEM_TYPE.SERVICE_COLOR
    },
    frequency: {
        title: 'frequency',
        style: 'col --frequence',
        type: LIST_TABLE_ITEM_TYPE.SERVICE_FREQUENCY
    }
};

export const getGridColumnsSettingService = (actionsStatus, tab) => {
    const newContent = { ...contentConfig };
    Object.keys(newContent).forEach((key) => {
        if (key === 'name' || key === 'color_code') {
            newContent[key] = { ...newContent[key], isDelete: tab === TAB.DEFAULT_SELECTED.DELETE };
        }
    });
    return {
        header: mapCurrentValueHeader(actionsStatus, { ...header }),
        columns,
        contentConfig: newContent
    };
};

export const getGridColumnsSettingServicePlan = (actionsStatus, tab) => {
    const header = {
        name: {
            title: 'plan name',
            style: 'col',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        length: {
            title: 'plan length',
            style: 'col col-date',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        }
    };
    const columns = [
        {
            id: 'name',
            title: 'name',
            status: true
        },
        {
            id: 'length',
            title: 'length',
            status: true
        }
    ];
    const contentConfig = {
        name: {
            title: 'name',
            style: 'col --name',
            type: LIST_TABLE_ITEM_TYPE.SERVICE_NAME
        },
        length: {
            title: 'length',
            style: 'col col-date',
            type: LIST_TABLE_ITEM_TYPE.PLAN_LENGTH
        }
    };
    const newContent = { ...contentConfig };
    Object.keys(newContent).forEach((key) => {
        if (key === 'name' || key === 'color_code') {
            newContent[key] = { ...newContent[key], isDelete: tab === TAB.DEFAULT_SELECTED.DELETE };
        }
    });
    return {
        header: mapCurrentValueHeader(actionsStatus, { ...header }),
        columns,
        contentConfig: newContent
    };
};

export const MESSAGES_TYPE_WARNING = {
    [BUTTON_ACTION_ACTIVE.id]: i18n.t('setting:active'),
    [BUTTON_ACTION_ARCHIVE.id]: i18n.t('setting:archive'),
    [BUTTON_ACTION_DELETE.id]: i18n.t('setting:delete'),
    [BUTTON_ACTION_UNDELETE.id]: i18n.t('setting:un_delete'),
    [BUTTON_ACTION_PERMANENTLY_DELETE.id]: i18n.t('setting:permanently_delete')
};
export const STATE_TAB_SERVICE_PLAN = {
    [TAB.DEFAULT_SELECTED.ACTIVE]: 1,
    [TAB.DEFAULT_SELECTED.ARCHIVE]: 2,
    [TAB.DEFAULT_SELECTED.DELETE]: 3
};
