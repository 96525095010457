import { reducer } from 'app/const/Reducer';
import IconClose from 'assets/icon/IconClose';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';

const TerminateConfirm = ({ onConfirm = () => {} }, ref) => {
    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false,
        checked: false,
        status: {}
    });

    const refConfirm = useRef(null);
    const refInput = useRef(null);

    useImperativeHandle(ref, () => ({
        showReason: _showReason
    }));

    const _showReason = (selected) => {
        dispatchState({
            isVisible: true,
            status: selected
        });
    };

    const _closeReason = () => {
        dispatchState({
            isVisible: false
        });
    };

    const _handleChangeReason = ({ target }) => {
        const value = target.value;
        if (!value.trim().length) {
            refConfirm.current.classList.add('is-disable');
        } else {
            refConfirm.current.classList.remove('is-disable');
        }
    };

    const _handleConfirm = () => {
        onConfirm({ ...state.status, reason: refInput.current.value });
        _closeReason();
    };

    if (!state.isVisible) {
        return null;
    }

    return (
        <div id="modal_confirm_terminate" className="modal container-modal open">
            <div className="modal__overlay bg-fixed" onClick={_closeReason} />
            <div className="modal__container">
                <div className="header-modal">
                    <h3 className="header-modal__label">Confirm Termination</h3>
                    <div className="v2-btn-default --icon-lg --transparent" onClick={_closeReason}>
                        <IconClose />
                    </div>
                </div>
                <div className="body-modal">
                    <p className="black-2 fw-500">
                        Terminating a service means you will no longer provide service to the customer moving forward,
                        and any recurring jobs will be stopped. The associated invoice will be voided, and this status
                        cannot be reversed.
                    </p>
                    <div className="flex-column gap-4 mt-2">
                        <p className="black-2 fw-500">Enter the reason for termination</p>
                        <input ref={refInput} type="text" className="field-input" onChange={_handleChangeReason} />
                    </div>
                </div>
                <div className="footer-modal">
                    <span className="v2-btn-default --transparent " onClick={_closeReason}>
                        Cancel
                    </span>
                    <div ref={refConfirm} onClick={_handleConfirm} className="v2-btn-main ml-2 is-disable">
                        Confirm
                    </div>
                </div>
            </div>
        </div>
    );
};

export default forwardRef(TerminateConfirm);
