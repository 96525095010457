import classNames from 'classnames';
import React, { Fragment, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { reducer } from 'app/const/Reducer';
import { LIST_DROPDOWN_SERVICE_PLAN_TYPE, PENDING_SERVICE_KEY } from 'app/const/Settings';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconDropDown from 'assets/icon/IconDropDown';
import IconTrash from 'assets/icon/IconTrash';
import ListService from './ListService';

const ItemService = ({ item = {}, onChangeData = () => {}, onDelete = () => {} }) => {
    const { t } = useTranslation('setting');
    const { delay: finalDelay = {}, id: finalId, name: finalName } = item;
    const { value: finalValue = 0, type: finalDelayType } = finalDelay;
    const [state, dispatchState] = useReducer(reducer, { isOpenDelete: false });
    const { isOpenDelete: finalIsOpenDelete } = state;

    const refValue = useRef(null);
    const refDropdown = useRef(null);

    const _handleChangeType = (id) => {
        onChangeData(finalId, id, PENDING_SERVICE_KEY.TYPE);
        refDropdown.current?._closeDropdown();
    };

    const _handleOpenDelete = () => {
        dispatchState((prev) => ({ ...prev, isOpenDelete: true }));
    };

    const _handleCloseDelete = () => {
        dispatchState((prev) => ({ ...prev, isOpenDelete: false }));
    };

    const _handleChangeValue = () => {
        const finalValue = parseInt(refValue.current.value) || 1;
        refValue.current.value = finalValue;
        onChangeData(finalId, finalValue, PENDING_SERVICE_KEY.VALUE);
    };

    const _handleChangeService = (value) => {
        onChangeData(finalId, Number(value), PENDING_SERVICE_KEY.SERVICE_ID);
    };

    return (
        <div className="rows row-has-line border-top-border-color-grey flex-column gap-8">
            <div className="flex-betweenitems w-100">
                <div className="flex-1 flexcenter gap-4">
                    <p>{t('trigger_following_service_template')}</p>
                    <div className="has-many-field__items phone-field mb-0">
                        <input
                            ref={refValue}
                            type="number"
                            className="field-input"
                            defaultValue={finalValue}
                            placeholder=""
                            min="1"
                            onBlur={_handleChangeValue}
                        />
                        <CalendarDropdown
                            ref={refDropdown}
                            buttonClassName="dropbtn items bg-white"
                            customDropButton={() => (
                                <Fragment>
                                    {LIST_DROPDOWN_SERVICE_PLAN_TYPE.find((item) => item.id === finalDelayType).value}{' '}
                                    <IconDropDown />
                                </Fragment>
                            )}
                        >
                            <ul>
                                {LIST_DROPDOWN_SERVICE_PLAN_TYPE.map(({ id, value }) => (
                                    <li
                                        key={id}
                                        className={classNames('items', { active: finalDelayType === id })}
                                        onClick={() => _handleChangeType(id)}
                                    >
                                        {value}
                                    </li>
                                ))}
                            </ul>
                        </CalendarDropdown>
                    </div>
                    <p>{t('after_previous_service_completed')}</p>
                </div>
                <div className={classNames('delete-items tabs relative', { active: finalIsOpenDelete })}>
                    <div className="v2-btn-default --icon-lg has-bg-red" onClick={_handleOpenDelete}>
                        <IconTrash />
                    </div>
                    <div className="delete-option">
                        <div className="tabs-daily btn-item red-items">
                            <button
                                className="tab-items active-tab-selector item-off"
                                onClick={() => onDelete(finalId)}
                                type="button"
                            >
                                {t('delete_service')}
                            </button>
                            <button className="tab-items" onClick={_handleCloseDelete} type="button">
                                {t('cancel')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <ListService serviceSelected={{ id: finalId, name: finalName }} onChangeService={_handleChangeService} />
        </div>
    );
};

export default ItemService;
