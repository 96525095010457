import React from 'react';

import { ROUTING_UNITS } from 'app/modules/addons/advanceRouting/constants';
import { convertSecondsToETA } from 'common/utils/TimeUtils';
import classNames from 'classnames';

const EventDriveTime = ({ customStyle = {}, time, distance = {}, isOverlap = false }) => {
    const timeArrived = convertSecondsToETA(time || 0);
    return (
        <div
            className={classNames('drive-time', { '--purple': !isOverlap, '--red': isOverlap })}
            style={{ height: customStyle.height }}
        >
            <p className="fs-10 fw-600">
                <span>{timeArrived} </span>
                {distance.value}
                {ROUTING_UNITS[distance.unit]?.label?.toLowerCase()}
            </p>
        </div>
    );
};

export default EventDriveTime;
