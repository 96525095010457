import ReactDOM from 'react-dom/client';
import { createPopper } from '@popperjs/core';
import {
    DEFAULT_ALL,
    KEY_OPTIONS_CHECK_NOTIFY_TECHNICIAN_JOB_CALENDAR,
    KEY_OPTIONS_CHECK_RECURRING_JOB_CALENDAR
} from 'app/const/App';
import { JOB_EXCLUDE, JOB_PENDING_CONFIRMATION, JOB_RECURRENCE } from 'app/const/Job';
import {
    CALENDAR_MODES,
    ID_POPPER_ADD_JOB,
    ID_TIME_WINDOW_FRAME,
    MAP_JOB_STATUS,
    MAP_TYPES,
    MAP_WEEK_DAYS,
    TASK_BACKGROUND_DEFAULT,
    TASK_BORDER_DEFAULT,
    TASK_CLASS_NAME,
    TASK_TEXT_DEFAULT,
    TIME_WINDOW_HORIZONTAL_CLASS,
    TIME_WINDOW_VERTICAL_CLASS
} from 'app/modules/calendar/const';
import { convertTimeToISO } from 'common/utils/DateUtils';
import moment from 'moment';
import JobSelected from '../map/components/JobSelected';
import { getJobStatus } from 'common/utils/JobStatusUtils';
import { setLocalStorage } from 'common/utils/LocalStorageUtils';
import { UN_MAGNET_VALUE } from '../components/magnet/components/constants';
import { getUserInfo } from 'common/utils/CompanyUserUtils';

export const sortEventsListView = (events = []) => {
    const sortedEvents = [...events].sort((a, b) => {
        const momentA = moment.utc(a.start);
        const momentB = moment.utc(b.start);
        if (+a.resourceId > +b.resourceId) return 1;
        if (+a.resourceId < +b.resourceId) return -1;
        if (momentA.isBefore(momentB)) return -1;
        if (momentA.isAfter(momentB)) return 1;

        return 0;
    });

    return sortedEvents;
};

export const convertEvents = (oldEvents = [], typeMove = 'move', isDisplayTask = true, isRouteOptimizer = false) => {
    const result = [];

    oldEvents.forEach((event) => {
        if (event.type === 'task') {
            if (isDisplayTask) result.push(convertTaskEvent(event));
        } else {
            result.push(convertOneEvent(event, typeMove, isRouteOptimizer));
        }
    });

    return result;
};

export const convertTaskEvent = (job) => {
    return {
        ...job,
        dateData: job.date,
        allDay: true,
        editable: false,
        resourceEditable: false,
        textColor: TASK_TEXT_DEFAULT,
        backgroundColor: TASK_BACKGROUND_DEFAULT,
        borderColor: TASK_BORDER_DEFAULT,
        resourceId: job?.schedule?.id || '',
        classNames: TASK_CLASS_NAME
    };
};

export const convertEventsDriveTime = (data) => {
    return data.map((item) => ({
        ...item,
        resourceId: item.schedule_id,
        id: item.start_job_id + '_drive_time' + item.end_job_id + item.schedule_id,
        display: 'background',
        isDriveTime: true,
        start: item.start_drive,
        end: item.end_drive
    }));
};

export const convertOneEvent = (job, typeMove, isRouteOptimizer = false) => {
    const resourceId = job?.schedule?.id || '';
    const jobId = job?.job?.id || '';
    const isActiveEdit =
        job?.editable && !JOB_EXCLUDE.RESIZE_JOB.includes(getJobStatus(job?.job?.status)?.type) && !!!job.job.locked;
    const idEventCalendar = jobId + '_' + resourceId + `${job?.type ? `_${job?.type}` : ''}`;

    const finalEvent = {
        jobId,
        resourceId,
        colorEvent: job?.color,
        tile: job?.tile,
        editable: isActiveEdit,
        resourceEditable: isActiveEdit,
        schedule: job?.schedule,
        active_job: job?.active_job,
        draft_invoice: job?.draft_invoice,
        circle_notify: job?.circle_notify,
        event: { ...job?.event },
        start: job?.event?.start || job.start,
        ref_start: job?.event?.start || job.start,
        end: job?.event?.end || job.end,
        repeat: job?.event?.repeat || false,
        eventId: job?.event?.id,
        all_day: job?.all_day,
        color: job?.color?.background,
        borderColor: job?.color?.border,
        customer: job?.customer,
        location: job?.location,
        show_map: job?.show_map,
        is_workpool: job?.is_workpool,
        type: job?.type,
        linked_job: job?.linked_job,
        invoice: job.invoice,
        additional_schedules: job.additional_schedules,
        time_window: job.time_window,
        schedule_color: job?.schedule_color,
        typeMove,
        jobIdTimeWindow: idEventCalendar,
        date_and_time: job?.date_and_time,
        calendar_time: job?.calendar_time,
        schedule_agenda: job?.schedule_agenda || {},
        ...job.job,
        id: idEventCalendar,
        job_state: job.job_state,
        has_pending_jobs: job.has_pending_jobs
    };

    if (isRouteOptimizer) {
        finalEvent.active_route = job?.active_route;
        finalEvent['id'] = jobId;
    }
    return finalEvent;
};

/**
 * Convert events to markers.
 * @param {array} events Events
 * @returns {array} markers list with condition.
 */
export const eventsToMarker = (events = [], ignoreCheckShow = false) => {
    const markers = [];
    const eventGrouped = groupEventsByDay(events);
    const duplicatedGeos = [];

    [...events].forEach((event) => {
        const isValidType = ['job', 'timeoff', 'event'].includes(event.type);
        const isSchedule = event.type !== 'job' && event.type !== 'task' && !isValidType;
        if (
            (!isSchedule || ignoreCheckShow) &&
            (event.show_map || ignoreCheckShow) &&
            (isValidType || ignoreCheckShow) &&
            event.location.lng &&
            event.location.lat
        ) {
            let allEventsOnDay;
            if (!ignoreCheckShow) {
                allEventsOnDay = eventGrouped[`${getKeyForGroupDay(event.start, event.resourceId)}`]?.['items'];
                if (!allEventsOnDay) return;
            }
            const marker = {
                ...event,
                location: event.location || {},
                isSchedule,
                markerContent: 1,
                idMarker: generateIdMarker(event.eventId, null, false, event?.schedule?.id || ''),
                coordinates: handleGetCoordinates(event.location),
                jobId: event?.event?.id || event?.id || '',
                serviceName: event.name,
                event,
                isWorkPool: event.is_workpool,
                isCustomMarker: event.type === 'timeoff' || event.type === 'event',
                status: event.status
            };

            // Check duplicated geos
            const markerDuplicated =
                duplicatedGeos.find((item) => item?.coordinates === marker?.coordinates?.join(',')) || null;
            if (!!markerDuplicated) {
                const lng = (markerDuplicated?.step?.lng ?? 0) + 0.000005;
                const lat = (markerDuplicated?.step?.lat ?? 0) - 0.000005;

                markerDuplicated['step'] = { lng, lat };
                marker.coordinates[0] = marker.coordinates[0] + lng;
                marker.coordinates[1] = marker.coordinates[1] + lat;
            } else {
                duplicatedGeos.push({ coordinates: marker.coordinates.join(','), step: { lng: 0, lat: 0 } });
            }

            if (!ignoreCheckShow) {
                const sortOrder = allEventsOnDay
                    .filter((item) => item?.type === 'job')
                    .sort((a, b) => moment(a.start).utc().unix() - moment(b.start).utc().unix());

                const newMarkerIndex = sortOrder.findIndex((marker) => marker.eventId === event.eventId) + 1;
                if (newMarkerIndex > 0) marker['markerContent'] = newMarkerIndex;
            }
            markers.push(marker);
        }

        if (isSchedule) {
            const templateMarker = { schedule: { id: event.id, user_id: event.user_id }, isSchedule };

            markers.push({
                ...templateMarker,
                idMarker: generateIdMarker(event.id, event?.start_point?.lat, isSchedule),
                isStartPoint: true,
                location: event.start_point,
                coordinates: handleGetCoordinates(event.start_point),
                markerContent: 'S'
            });
            markers.push({
                ...templateMarker,
                isEndPoint: true,
                idMarker: generateIdMarker(event.id, event?.end_point?.lat, isSchedule),
                location: event.end_point,
                coordinates: handleGetCoordinates(event.end_point),
                markerContent: 'E'
            });
        }
    });

    return markers;
};

/**
 * Check if a value is outside of a range.
 * @param value - The value to check
 * @param min - The minimum value the input can be.
 * @param max - The maximum value the user can enter.
 * @returns the value of the expression.
 */
const checkRange = (value, min, max) => {
    return +value < min || +value > max;
};

/**
 * It takes a location object with a lat and lng property, and returns an array of the coordinates if they are valid,
 * otherwise it returns null
 * @param location -
 * @returns An array of coordinates
 */
const handleGetCoordinates = (location) => {
    if (location && location.lat && location.lng) {
        let result = null;
        if (isNaN(+location.lng) || checkRange(+location.lng, -180, 180)) return null;
        if (isNaN(+location.lat) || checkRange(+location.lat, -90, 90)) return null;
        result = [+location.lng, +location.lat];
        return result;
    }
    return null;
};

/**
 * It generates a unique id for a marker
 * @param eventId - The id of the event
 * @param [lat=null] - latitude of the marker
 * @param [isSchedule=false] - This is a boolean value that determines whether the marker is for a
 * schedule or not.
 * @returns A string that is the eventId and lat if lat is not null and isSchedule is true.
 */
export const generateIdMarker = (eventId, lat = null, isSchedule = false, scheduleId = '') => {
    if (lat && isSchedule) return `${eventId}_${lat}_isSchedule`;
    return scheduleId ? `${eventId}_${scheduleId}` : `${eventId}`;
};

/* It checks if the job is passed the filter. */
export const checkFilterMapCalendar = (job, filters) => {
    const filterType = filters[MAP_TYPES] || null;
    const isAllTypeFilter = filterType === -1 || filterType?.[0] === '-1';
    const eventType = job?.event?.type;

    let isPassedType = isAllTypeFilter;
    if (!isPassedType) {
        const isJobWorkPool = eventType === 'job' && job?.isWorkPool;
        if (isJobWorkPool) {
            isPassedType = filterType.includes('work_pool');
        } else {
            isPassedType = eventType === 'job' ? true : filterType.includes(eventType);
        }
    }

    const isJobRecurring = !!job.recurrence;
    const filterStatus = filters[MAP_JOB_STATUS] || null;
    const filterDays = filters[MAP_WEEK_DAYS] || null;
    const dayOfJob = moment(job.start).utc().format('dddd').toLocaleLowerCase();
    const isAllDayFilter = filterDays === -1 || filterDays?.[0] === '-1';
    const isAllStatusFilter =
        filterStatus === -1 || filterStatus === '-1' || filterStatus?.[0] === -1 || filterStatus?.[0] === '-1';
    let isPassedStatus = !!isAllStatusFilter || job.isCustomMarker;
    const isPassedDays = filterDays && (isAllDayFilter || filterDays.includes(dayOfJob));

    // Is all status filter or status filter include recurrence
    const includeRecurrence =
        (Array.isArray(filterStatus) && filterStatus.some((item) => item === JOB_RECURRENCE)) || isAllStatusFilter;
    // Check if job is recurring and filter status have status recurring
    const isPassedRecurring = includeRecurrence && isJobRecurring;

    // If filter is not all status filter
    if (!isPassedStatus) {
        if (includeRecurrence) {
            // If filter status have status recurring or status match
            isPassedStatus = isPassedRecurring || filterStatus.some((item) => item === job.status?.toString());
        } else {
            // Normal check with status of list filter
            isPassedStatus = !isJobRecurring && filterStatus.some((item) => item === job.status?.toString());
        }
    }

    return isPassedDays && (isPassedStatus || ['timeoff', 'event'].includes(eventType)) && isPassedType;
};

/* It checks if the job is passed the filter. */
export const checkFilterMapRouteOptimize = (job, filters) => {
    const isJobRecurring = !!job.recurrence;
    const filterStatus = filters[MAP_JOB_STATUS] || null;
    const filterDays = filters[MAP_WEEK_DAYS] || null;
    const dayOfJob = moment(job.start).utc().format('dddd').toLocaleLowerCase();
    const isAllDayFilter = filterDays === -1 || filterDays?.[0] === '-1';
    const isAllStatusFilter =
        filterStatus === -1 || filterStatus === '-1' || filterStatus?.[0] === -1 || filterStatus?.[0] === '-1';

    let isPassedStatus = !!isAllStatusFilter;
    const isPassedDays = filterDays && (isAllDayFilter || filterDays.includes(dayOfJob));

    // Is all status filter or status filter include recurrence
    const includeRecurrence =
        (Array.isArray(filterStatus) && filterStatus.some((item) => parseInt(item) === JOB_RECURRENCE)) ||
        isAllStatusFilter;
    // Check if job is recurring and filter status have status recurring
    const isPassedRecurring = includeRecurrence && isJobRecurring;

    // If filter is not all status filter
    if (!isPassedStatus) {
        if (includeRecurrence) {
            // If filter status have status recurring or status match
            isPassedStatus = isPassedRecurring || filterStatus.some((item) => parseInt(item) === parseInt(job.status));
        } else {
            // Normal check with status of list filter
            isPassedStatus = !isJobRecurring && filterStatus.some((item) => parseInt(item) === parseInt(job.status));
        }
    }
    return isPassedDays && isPassedStatus;
};

/**
 * Group events to object with key is day with format DD-MM-YYYY.
 * @param {array} events - List events
 * @returns {object} List events grouped by day.
 */
const groupEventsByDay = (events) => {
    const eventsGrouped = {};

    [...events].forEach((item) => {
        const isValidType = ['job', 'timeoff', 'event'].includes(item.type);
        if (isValidType && item.show_map && item.location.lat) {
            const key = getKeyForGroupDay(item.start, item.resourceId);
            eventsGrouped[key] = { items: [...(eventsGrouped[key]?.items || []), item] };
        }
    });

    return eventsGrouped;
};

/**
 * It takes a date and a resourceId and returns a string that is the date formatted as YYYY-MM-DD and
 * the resourceId separated by an underscore
 * @param date - The date of the event
 * @param resourceId - The id of the resource you want to get the events for.
 */
const getKeyForGroupDay = (date, resourceId) =>
    `${moment(date).utc().startOf('day').format('YYYY-MM-DD')}_${resourceId}`;

const CALENDAR_WEEK_VIEWS = [
    CALENDAR_MODES.AGENDA_WEEK,
    CALENDAR_MODES.AGENDA_2_WEEK,
    CALENDAR_MODES.AGENDA_3_WEEK,
    CALENDAR_MODES.AGENDA_4_WEEK
];

export const checkCalendarViewWeek = (currentView) => {
    return CALENDAR_WEEK_VIEWS.includes(currentView);
};

export const checkShowWeekends = (currentView) => {
    return [
        CALENDAR_MODES.AGENDA_WEEK,
        CALENDAR_MODES.AGENDA_2_WEEK,
        CALENDAR_MODES.DAY_GRID_MONTH,
        CALENDAR_MODES.LIST_DAY,
        CALENDAR_MODES.LIST_WEEK,
        CALENDAR_MODES.LIST_MONTH
    ].includes(currentView);
};

export const isAlwayShowWeekends = (currentView) => {
    return (
        currentView === CALENDAR_MODES.AGENDA_DAY ||
        currentView === CALENDAR_MODES.LIST_DAY ||
        currentView === CALENDAR_MODES.AGENDA_3_DAY ||
        currentView === CALENDAR_MODES.AGENDA_4_DAY
    );
};

export const isEventOverDiv = (x, y, idDivCompare = 'external-events-work-pool') => {
    const external_events = document.getElementById(idDivCompare);
    if (!external_events) return false;
    var offset = external_events.getBoundingClientRect();

    // Compare
    if (x >= offset.left && y >= offset.top && x <= offset.right && y <= offset.bottom) {
        return true;
    }
    return false;
};

// Handle add job/timeoff popper
export const handleCalculatePositionPopper = (xAxisElement, yAxisElement) => {
    const rectBounding1 = xAxisElement.getBoundingClientRect();
    const rectBounding2 = yAxisElement.getBoundingClientRect();
    const r1 = { x: rectBounding1.x, y: rectBounding1.y, w: rectBounding1.width, h: rectBounding1.height };
    const r2 = { x: rectBounding2.x, y: rectBounding2.y, w: rectBounding2.width, h: rectBounding2.height };
    return intersectingRect(r1, r2);
};

const intersectingRect = (r1, r2) => {
    var x = Math.max(r1.x, r2.x);
    var y = Math.max(r1.y, r2.y);
    var xx = Math.min(r1.x + r1.w, r2.x + r2.w);
    var yy = Math.min(r1.y + r1.h, r2.y + r2.h);
    return { x: x, y: y, w: xx - x, h: yy - y };
};

export const handleCreatePopper = (columnEl, rowEl, time = null) => {
    // Get the popper element by intersectingRect of columEl and rowEl
    const { x, y, w: width, h: height } = handleCalculatePositionPopper(columnEl, rowEl);
    // Create div for popper and apply to document
    const popper = document.createElement('div');
    popper.style.cssText = `position:fixed;z-index:9999999;top:${y}px;left:${x}px;width:${width}px;height:${height}px`;
    popper.classList = 'wrap-addjob';
    popper.id = ID_POPPER_ADD_JOB;
    document.body.appendChild(popper);

    // Pure HTML Time stamp preview
    const previewTime = document.createElement('p');
    previewTime.innerHTML = `${time?.start || ''} - ${time?.end || ''}`;
    previewTime.style.cssText = `white-space:nowrap;font-size:9px;line-height:10px;background:#8c69dc;color:#FFF;padding:0 1px;margin:-1px;overflow:hidden;`;
    popper.appendChild(previewTime);
    // End Pure HTML Time stamp preview

    const divTooltip = document.getElementById('tooltip-add-job');
    const divTooltipBg = document.getElementById('tooltip-add-job-bg');
    if (divTooltip) divTooltip.style.display = 'block';
    if (divTooltipBg) divTooltipBg.style.display = 'block';

    const tooltip = document.getElementById('tooltip-add-job');
    if (popper && tooltip) {
        createPopper(popper, tooltip, {
            placement: 'top',
            strategy: 'fixed',
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 5]
                    }
                }
            ]
        });
    }
};
// End handle add job/timeoff popper

// Handle window time
export const setDateTimeWindow = ({ newStartTime, oldTimeWindow = {} }) => {
    const newDate = moment(newStartTime).utc();
    const newData = { year: newDate.year(), month: newDate.month(), date: newDate.date() };
    return {
        start: convertTimeToISO(moment(oldTimeWindow.start).utc().set(newData)),
        end: convertTimeToISO(moment(oldTimeWindow.end).utc().set(newData))
    };
};

const createElementFromHTML = (htmlString) => {
    const div = document.createElement('div');
    div.innerHTML = htmlString.trim();
    return div.firstChild;
};

const TIME_WINDOW_HTML_STRING = `<div id="${ID_TIME_WINDOW_FRAME}"><div class="frame-timewindown__title">Time Window</div></div>`;

export const handleDisplayTimeWindow = (elReference, style, position, infoCalendar, infoJob) => {
    const divCreated = createElementFromHTML(TIME_WINDOW_HTML_STRING);
    const divParent = elReference.closest('.fc-timegrid-col-frame') || elReference.closest('.fc-timeline-lane-frame');

    // Handle style of element time window
    divCreated.style.cssText = 'position: absolute;z-index:2';
    divCreated.className = infoCalendar.isVerticalMode ? TIME_WINDOW_VERTICAL_CLASS : TIME_WINDOW_HORIZONTAL_CLASS;
    divCreated.style.width = infoCalendar.isVerticalMode ? '100%' : style.width;
    divCreated.style.height = infoCalendar.isVerticalMode ? style.height : '100%';
    divCreated.style.top = position.height || 0;
    divCreated.style.left = position.width || 0;
    divCreated.setAttribute('data-timewindow-start-job', infoJob.startDay);
    divCreated.setAttribute('data-timewindow-time-start', infoJob.time_window?.start || 'null');
    divCreated.setAttribute('data-timewindow-time-end', infoJob.time_window?.end || 'null');
    // End handle style of element time window

    divParent && divParent.appendChild(divCreated);
};

export const handleRemoveTimeWindowEl = () => {
    const timeWindowEl = document.getElementById(ID_TIME_WINDOW_FRAME);
    if (timeWindowEl) timeWindowEl.remove();
};

export const handleUpdateTimeWindowEl = (style, position, time_window) => {
    const divTimeWindow = document.getElementById(ID_TIME_WINDOW_FRAME);
    divTimeWindow.style.width = style.width || '100%';
    divTimeWindow.style.height = style.height || '100%';
    divTimeWindow.style.top = position.height || 0;
    divTimeWindow.style.left = position.width || 0;
    divTimeWindow.setAttribute('data-timewindow-time-start', time_window?.start || 'null');
    divTimeWindow.setAttribute('data-timewindow-time-end', time_window?.end || 'null');
};

export const handleGetDataTimeWindow = ({
    time_window,
    eventStartDay,
    activeStart,
    isVerticalMode,
    isGetPosition = false,
    business_hours = {},
    infoCalendar = {}
}) => {
    let startTimeCompany = null;
    let endTimeCompany = null;
    const dataTimeWindow = { startStr: time_window?.start, endStr: time_window?.end, ...infoCalendar };

    if (time_window === null) {
        const timeBusinessStart = moment(business_hours.start, 'HH:mm');
        const timeBusinessEnd = moment(business_hours.end, 'HH:mm');
        const startUTC = moment(eventStartDay)
            .startOf('date')
            .add(timeBusinessStart.hours(), 'hours')
            .add(timeBusinessStart.minutes(), 'minutes');
        const endUTC = moment(eventStartDay)
            .startOf('date')
            .add(timeBusinessEnd.hours(), 'hours')
            .add(timeBusinessEnd.minutes(), 'minutes');

        startTimeCompany = convertTimeToISO(startUTC);
        endTimeCompany = convertTimeToISO(endUTC);
        dataTimeWindow['startStr'] = startTimeCompany;
        dataTimeWindow['endStr'] = endTimeCompany;
    }

    if (isGetPosition) {
        dataTimeWindow['startStr'] = startTimeCompany || time_window?.start;
        dataTimeWindow['endStr'] = isVerticalMode ? eventStartDay : activeStart;
        dataTimeWindow['isGetPosition'] = true;
    }

    return dataTimeWindow;
};

// End handle window time

export const handleGetCustomStyleEvent = ({
    startStr,
    endStr,
    widthColumn = 10,
    heightRow = 10,
    isMonthView = false,
    isTwoWeekView = false,
    isVerticalMode = false,
    isGetPosition = false,
    isPastEvent = false
}) => {
    let customStyle = {};
    const startMoment = moment(startStr);
    const endMoment = isGetPosition ? moment(endStr).utc().startOf('day') : moment(endStr);
    const timeRange = isGetPosition
        ? moment.duration(startMoment.diff(endMoment)).asMinutes()
        : moment.duration(endMoment.diff(startMoment)).asMinutes();

    if (!isMonthView) {
        customStyle = {
            width: `${(widthColumn / 15) * timeRange}px`,
            height: `${(heightRow / 15) * timeRange}px`
        };

        if (!isVerticalMode && isTwoWeekView) customStyle.width = `${(widthColumn / 60) * timeRange}px`;
        isVerticalMode ? delete customStyle.width : delete customStyle.height;
    } else {
        if (!isVerticalMode) {
            const startDayOfStartMoment = moment(startStr).utc().startOf('day').unix();
            const endDayOfEndMoment = moment(endStr).utc().startOf('day').unix();

            if (endDayOfEndMoment > startDayOfStartMoment) {
                customStyle = { width: `${widthColumn * 2}px` };
            } else {
                customStyle = { width: `${widthColumn}px` };
            }
        } else {
            // Remove custom style and use style from fullcalendar
            customStyle = {};
        }
    }

    if (isPastEvent) customStyle = { ...customStyle, opacity: 0.6 };
    return customStyle;
};

// Map functions utils
export const _handleGetJobsWithinPolygon = (data) => {
    if (!Array.isArray(data) || !data.length) return [];
    const results = [];
    data.forEach((marker) => {
        if (!_handleCheckMarkerValid(marker) || !marker.coordinates) return;
        results.push({
            id: marker.event.id,
            idMarker: marker.idMarker,
            type: 'Feature',
            properties: { id: marker.idMarker, currentEvent: marker.event },
            geometry: { type: 'Point', coordinates: marker.coordinates }
        });
    });
    return results;
};

const _handleCheckMarkerValid = (marker) => {
    if (marker.isSchedule || marker?.event?.type !== 'job' || marker?.event?.locked === 1) return false;
    return !handleCheckMarkerLocked(marker.event.status);
};

export const handleCheckMarkerLocked = (status) => {
    return JOB_EXCLUDE.RESIZE_JOB.includes(getJobStatus(status)?.type);
};

export const _handleCreateEventDragging = (event, currentEvent, onMouseUp = () => {}) => {
    const touch = event.touches && event.touches[0];
    const newDiv = document.createElement('div');
    newDiv.id = 'marker-dragging';
    newDiv.style.zIndex = 99999;
    newDiv.style.cursor = 'grabbing';
    newDiv.style.position = 'absolute';
    newDiv.style.left = (event.clientX || touch.clientX) + 'px';
    newDiv.style.top = (event.clientY || touch.clientY) + 'px';
    newDiv.style.width = '160px';

    ReactDOM.createRoot(newDiv).render(
        <JobSelected currentEvent={currentEvent} headerTitle={currentEvent?.tile?.header} />
    );
    document.body.appendChild(newDiv);

    let dragging = true;
    let currentDroppable = null;
    let divPlaceholder = null;
    let indexInsert = null;
    const divWrapperSelected = document.getElementById('selected-drop');
    const dragBox = newDiv;
    const offset = {
        x: (event.clientX || touch.clientX) - dragBox.offsetLeft,
        y: (event.clientY || touch.clientY) - dragBox.offsetTop
    };

    const _handlePlaceholder = (e) => {
        const touch = e.touches && e.touches[0];
        const afterElement = getDragAfterElement(divWrapperSelected, e.clientY || touch.clientY);
        if (afterElement) {
            indexInsert = +afterElement.getAttribute('data-index');
        } else {
            indexInsert = null;
        }
        if (divPlaceholder) divWrapperSelected.insertBefore(divPlaceholder, afterElement);
    };

    const _handleMouseMove = (event) => {
        const touch = event.touches && event.touches[0];
        if (dragging) {
            dragBox.style.left = (event.clientX ?? touch.clientX) - offset.x + 'px';
            dragBox.style.top = (event.clientY ?? touch.clientY) - offset.y + 'px';

            newDiv.hidden = true;
            const elemBelow = document.elementFromPoint(event.clientX ?? touch.clientX, event.clientY ?? touch.clientY);
            newDiv.hidden = false;

            if (!elemBelow) return;

            // potential droppables are labeled with the class "droppable" (can be other logic)
            const droppableBelow = elemBelow.closest('.wrapper-drag__content');

            if (currentDroppable !== droppableBelow) {
                if (currentDroppable) {
                    if (divPlaceholder) {
                        divPlaceholder.remove();
                        divPlaceholder = null;
                        document.removeEventListener('mousemove', _handlePlaceholder);
                        document.removeEventListener('touchmove', _handlePlaceholder);
                    }
                }
                currentDroppable = droppableBelow;
                if (currentDroppable) {
                    divPlaceholder = document.createElement('div');
                    divPlaceholder.id = 'drag-tile';
                    divPlaceholder.className = 'drag-tile-main relative';
                    divPlaceholder.innerHTML = `<div class="drag-tile fc-event-block jobslist has-drag" />`;

                    const afterElement = getDragAfterElement(divWrapperSelected, event.clientY ?? touch.clientY);
                    if (!document.getElementById('drag-tile'))
                        divWrapperSelected.insertBefore(divPlaceholder, afterElement);
                    document.addEventListener('mousemove', _handlePlaceholder);
                    document.addEventListener('touchmove', _handlePlaceholder);
                }
            }
        }
    };

    const _handleMouseUp = () => {
        const divWrapperSelected = document.getElementById('selected-drop');
        if (divWrapperSelected && currentDroppable) {
            const event = new CustomEvent('dropevent', {
                detail: {
                    id: currentEvent.id,
                    idMarker: currentEvent.idMarker,
                    type: 'Feature',
                    properties: { id: currentEvent.idMarker, currentEvent },
                    indexInsert
                }
            });
            divWrapperSelected.dispatchEvent(event);
        }
        if (divPlaceholder) {
            divPlaceholder.remove();
            divPlaceholder = null;
            document.removeEventListener('mousemove', _handlePlaceholder);
        }
        dragging = false;
        currentDroppable = null;
        newDiv.remove();
        onMouseUp();
    };

    document.addEventListener('mousemove', _handleMouseMove);
    document.addEventListener('touchmove', _handleMouseMove);
    document.addEventListener('mouseup', _handleMouseUp);
    document.addEventListener('touchend', _handleMouseUp);
};

// Helper function to get the list item that the placeholder should be inserted after
function getDragAfterElement(list, y) {
    const listItems = Array.from(list.querySelectorAll('.drag-tile-main:not(#drag-tile)'));
    return listItems.reduce(
        (closest, listItem) => {
            const box = listItem.getBoundingClientRect();
            const offset = y - box.top - box.height / 2;
            if (offset < 0 && offset > closest.offset) {
                return { offset, element: listItem };
            } else {
                return closest;
            }
        },
        { offset: Number.NEGATIVE_INFINITY }
    ).element;
}

export const _handleHightLightEvents = (jobs, isActive = false) => {
    jobs.forEach((item) => {
        const currentEvent = document.querySelectorAll(`[id="${item.id}"]`);
        if (currentEvent) {
            currentEvent.forEach((elm) => {
                const colorEvent = item.properties.currentEvent.colorEvent;
                elm.style.borderStyle = isActive ? 'dashed' : 'solid';
                elm.style.borderColor = isActive ? '#999999' : colorEvent.border;
            });
        }
    });
};

export const _handleSetBackGroundColor = ({
    resourceId,
    date,
    background,
    backgroundAllSlot,
    widthAfter = 0,
    leftAfter = 0,
    isVerticalView = true,
    isMonth = false
}) => {
    if (isMonth) {
        const columns = getColumns({ date, typeGet: 'date' });
        columns.forEach((col) => {
            col.style.setProperty('background', background, 'important');
        });
    } else {
        if (isVerticalView) {
            const columns = getColumns({ resourceId, date, typeGet: 'dateAndResource' });
            const headers = document.querySelectorAll(`[data-header="true"][data-date="${date}"]`);
            columns.forEach((col) => {
                const isAllSlotColumn = col.closest('.fc-scrollgrid-sync-table');
                col.style.setProperty('background', isAllSlotColumn ? backgroundAllSlot : background, 'important');
            });
            headers.forEach((col) => {
                col.style.setProperty('background', background, 'important');
            });
        } else {
            const styleAfter = {
                content: '',
                top: 0,
                left: `${widthAfter * leftAfter}px`,
                position: 'absolute',
                width: `${widthAfter}px`,
                height: '100%',
                background
            };

            const rowResource = document.querySelector(`.fc-timeline-lane[data-resource-id="${resourceId}"]`);
            if (rowResource) {
                const childElement = document.querySelector('.fake-before-element');
                rowResource.style.setProperty('position', 'relative', 'important');

                if (!childElement) {
                    const beforeElement = document.createElement('div');
                    beforeElement.classList.add('fake-before-element');
                    rowResource.insertBefore(beforeElement, rowResource.firstChild);
                    for (const key in styleAfter) beforeElement.style.setProperty(key, styleAfter[key], 'important');
                } else if (childElement && background === 'transparent') {
                    childElement.remove();
                }
            }
        }
    }
};

const getColumns = ({ resourceId, date, typeGet }) => {
    let stringQuery = '';
    switch (typeGet) {
        case 'date':
            stringQuery = `[data-date="${date}"]`;
            break;
        case 'dateAndResource':
            stringQuery = `[data-resource-id="${resourceId}"][data-date="${date}"]`;
            break;
        case 'resource':
            stringQuery = `[data-resource-id="${resourceId}"]`;
            break;
        default:
            break;
    }
    return document.querySelectorAll(`${stringQuery}`);
};

export const isRecurringEvent = (event) => {
    return event.repeat === 1 && getJobStatus(event.status)?.type !== JOB_PENDING_CONFIRMATION;
};

/**
 * It takes an object with three properties, each of which can be either an array or a string, and returns an object with
 * three properties, each of which is an array
 * @returns An object with three properties: MAP_WEEK_DAYS, MAP_JOB_STATUS, and MAP_TYPES.
 */
export const getFilterMapData = ({ dayofmap, statusofmap, typeofmap }) => {
    let dataDays = DEFAULT_ALL;
    let dataStatuses = DEFAULT_ALL;
    let dataTypes = DEFAULT_ALL;
    if (Array.isArray(dayofmap)) dataDays = dayofmap;
    if (Array.isArray(statusofmap)) dataStatuses = statusofmap;
    if (Array.isArray(typeofmap)) dataTypes = typeofmap;
    if (typeof dayofmap === 'string') dataDays = dayofmap.split(',').filter((item) => item);
    if (typeof statusofmap === 'string') dataStatuses = statusofmap.split(',').filter((item) => item);
    if (typeof typeofmap === 'string') dataTypes = typeofmap.split(',').filter((item) => item);
    return {
        [MAP_WEEK_DAYS]: dataDays,
        [MAP_JOB_STATUS]: dataStatuses,
        [MAP_TYPES]: dataTypes
    };
};

export const checkEventVisible = (jobId) => {
    if (!jobId) return false;
    const scrollableContainer = document.querySelectorAll('.fc-scroller.fc-scroller-liquid-absolute')[1];
    const element = document.querySelector(`[id="${jobId}"]`)?.closest('.fc-timeline-event-harness');

    if (!element || !scrollableContainer) return false;

    const containerRect = scrollableContainer.getBoundingClientRect();
    const elementRect = element.getBoundingClientRect();

    // Check if element is fully or partially visible in the container's viewport
    const isVisible =
        elementRect.right >= containerRect.left &&
        elementRect.left <= containerRect.right &&
        elementRect.bottom >= containerRect.top &&
        elementRect.top <= containerRect.bottom;

    return isVisible;
};

export const makeEventVisibleMiddle = (jobId) => {
    const scrollableContainer = document.querySelectorAll('.fc-scroller.fc-scroller-liquid-absolute')[1];
    const element = document.querySelector(`[id="${jobId}"]`).closest('.fc-timeline-event-harness');
    const styles = window.getComputedStyle(element);
    const containerRect = scrollableContainer.getBoundingClientRect();
    const elementOffset = +styles.left.split('px')[0];
    const containerMiddle = containerRect.width / 2;
    const scrollOffset = elementOffset - containerMiddle;

    scrollableContainer.scrollTo({ left: scrollOffset });
};

export const restoreOptionJobCalendar = () => {
    setLocalStorage(KEY_OPTIONS_CHECK_RECURRING_JOB_CALENDAR, null);
    setLocalStorage(KEY_OPTIONS_CHECK_NOTIFY_TECHNICIAN_JOB_CALENDAR, null);
};

/**
 * Change the height of the slot in the calendar
 * @param {number} value - The value of the slot height
 * @param {HTMLElement} wrapperEl - The element to search for the slots
 */
export const updateSlotHeight = (value, wrapperEl = document) => {
    const allSlot = wrapperEl.querySelectorAll('.fc-timegrid-slot');
    if (allSlot[0] && allSlot[0].clientHeight !== value) allSlot.forEach((item) => (item.style.height = `${value}px`));
};

export const clearAdvanceRouteCalendar = ({ data = [], isClearMagnet = false, isClearDriveTime = false }) => {
    const result = [];
    data.forEach((item) => {
        // If clear drive time, remove all drive time
        if (isClearDriveTime && item.isDriveTime) return;
        // If clear magnet, remove all magnet
        if (isClearMagnet) {
            result.push({ ...item, is_magnet: false, schedule_ids: [], magnet_jobs: [], ...UN_MAGNET_VALUE });
        } else {
            result.push(item);
        }
    });
    return result;
};

/**
 * Creates and inserts a new HTML element representing an event in the list view.
 * @param {Object} params - The parameters for creating the event list view HTML.
 * @param {Object} params.infoEvent - Information about the current event.
 * @param {Object} params.extendedProps - Extended properties of the event.
 * @param {Array} params.finalSchedules - The final list of schedules.
 * @param {Object} [params.refElements={}] - Reference elements to keep track of created elements.
 */
export const createEventListViewHTML = ({ infoEvent, extendedProps, finalSchedules, refElements = {} }) => {
    const nextSibling = infoEvent.el?.nextElementSibling;
    const nextScheduleId = nextSibling?.querySelector('[data-schedule-id]')?.getAttribute('data-schedule-id');
    const colorHoliday = nextSibling?.querySelector('[data-color-holiday]')?.getAttribute('data-color-holiday');

    if (nextScheduleId && +extendedProps.schedule?.id !== +nextScheduleId && !nextSibling?.getAttribute('data-date')) {
        const nextScheduleInfo = finalSchedules.find((item) => +item.id === +nextScheduleId) || {};

        const user = getUserInfo(nextScheduleInfo?.user_id);

        const dateUtc = moment.utc(infoEvent.event.startStr);
        const isToday = dateUtc.isSame(moment.utc(), 'day');
        const dateNumber = dateUtc.format('DD');
        const formattedDate = dateUtc.format('MMM, ddd');

        // Create a new element
        const newElement = document.createElement('tr');
        refElements.current.push(newElement);
        newElement.id = `schedule-${nextScheduleId}`;
        newElement.className = 'fc-list-day fc-day';
        newElement.innerHTML = `
        <th scope="colgroup"></th>
        <div class="fc-list-day-cushion fc-cell-shaded">
        <div class="rows --title${isToday ? ' active bg-white' : ''} style="--color-holiday: ${colorHoliday || ''}" ">
        <div class="flexcenter">
            <div class="title-date flexcenter gap-6" style="visibility: hidden"><span class="date">${dateNumber}</span><span class="col-label">${formattedDate}</span></div>
            <div class="schedule-user">
            <div class="tech-name">
                ${
                    user
                        ? `<div class="avt-img"><img src="${user?.avatar}" width="24" height="24" /></div>`
                        : `<div class="avt">${nextScheduleInfo?.name?.substring(0, 2)}</div>`
                }
                <span class="txt-ellipsis">${nextScheduleInfo?.name}</span>
            </div>
            </div>
        </div>
        </div>
        </div>
        </th>`;
        // Insert the new element before infoEvent.el
        infoEvent.el.parentNode.insertBefore(newElement, infoEvent.el.nextElementSibling);
    }
};
