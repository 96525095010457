import React, { useReducer } from 'react';

import { reducer } from 'app/const/Reducer';
import BodyConversation from '../components/BodyConversation';
import FooterConversation from '../components/FooterConversation';
import HeaderConversation from '../components/HeaderConversation';

const AIConversationDetail = ({ onClose = () => {} }) => {
    const [state, dispatchState] = useReducer(reducer, { data: [] });
    const { data: finalData } = state;

    const _handleAddData = ({ data = {} }) => {
        dispatchState((prev) => ({ ...prev, data: [...prev.data, data] }));
    };

    return (
        <div className="conversation-sms notification-dropdown active">
            <HeaderConversation onClose={onClose} />
            <BodyConversation data={finalData} />
            <FooterConversation onAddData={_handleAddData} />
        </div>
    );
};

export default AIConversationDetail;
