import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import PlanDetail from '../plan';
import { reducer } from 'app/const/Reducer';
import { getNewDateWithDelay } from 'common/utils/JobUtils';
import { JOB_STATE } from 'app/modules/jobdetail/const';

function PlanJobs({ servicePlan, triggerUpdate }, ref) {
    const itemRefs = useRef([]);

    const [state, dispatchState] = useReducer(reducer, {
        plans: [...servicePlan],
        shouldUpdate: 0
    });

    const { plans: currentPlans, shouldUpdate } = state;
    const numberArray = currentPlans.length;

    useImperativeHandle(ref, () => ({
        getValue: _getJobData
    }));

    useEffect(() => {
        if (triggerUpdate) {
            dispatchState({
                plans: [...servicePlan],
                shouldUpdate: triggerUpdate
            });
        }
    }, [triggerUpdate]);

    const _getJobData = () => {
        return itemRefs.current.map((item) => item.getValue());
    };

    const _updateNewDate = (id, newDate, position) => {
        dispatchState((prev) => {
            const newPlans = prev.plans.map((item) => {
                if (item.serviceItemId === id) {
                    return {
                        ...item,
                        start: newDate
                    };
                }
                return item;
            });

            for (let i = position + 1; i < numberArray; i++) {
                newPlans[i]['start'] = getNewDateWithDelay(newPlans[i - 1].start, newPlans[i].delay);
            }
            return {
                ...prev,
                plans: newPlans
            };
        });
    };

    const _renderItems = () => {
        return servicePlan.map((item, index) => {
            const currentItem = currentPlans[index] || {};

            return (
                <PlanDetail
                    ref={(el) => (itemRefs.current[index] = el)}
                    key={index}
                    data={{ ...item, job_state: item.job_state || JOB_STATE.CREATING }}
                    index={index}
                    onUpdateDate={_updateNewDate}
                    defaultStart={currentItem.start}
                    isEnd={index === numberArray - 1}
                    shouldUpdate={shouldUpdate}
                />
            );
        });
    };

    return <div className="details-job__content detail-plan schedule-wrapper">{_renderItems()}</div>;
}

export default forwardRef(PlanJobs);
