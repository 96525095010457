import ButtonSave from 'app/components/button/ButtonSave';
import GdGridView from 'app/components/grid/GdGridView';
import GDModalWarning from 'app/components/modal/ModalWarning';
import StatusBar from 'app/components/status/statusbar';
import { getListLocationCustomer } from 'app/const/Api';
import { DEFAULT_PAGE, KEY_REPORT_LOCAL_STORAGE, LIST_STATUS } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { DELETE_LOCATION_CUSTOMER, UN_DELETE_LOCATION, updateTagsLocation } from 'app/const/api/V2';
import { getGridColumnsCustomerLocation } from 'app/const/customer/CustomerLocation';
import { TABS_FILTER } from 'app/const/report/ReportFilter';
import { getDefaultParams } from 'app/const/report/ReportParams';
import { REPORT_TYPE } from 'app/const/report/ReportTypeContent';
import {
    customerLocation,
    customerLocationItem,
    handleAbortController,
    resetPagingLocation
} from 'app/modules/customer/utils';
import { openLocation } from 'common/redux/actions/location';
import { clientQuery } from 'common/utils/ApiUtils';
import { handleActionHeaderReport } from 'common/utils/GridViewUtils';
import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import { React, useContext, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { KEY_LOCATION_FILTER_SELECTED } from '../../const';
import { CustomerDetailContext } from '../context/CustomerDetailContext';
import { _handleSortTags } from '../context/utils';
import LocationRealtime from './Service';
import LocationActions from './components/LocationActions';
import { displayLocation, responseToLocationSelected } from './utils';
import ReportPagination from 'app/modules/report/components/ReportPagination';
import { TYPE_TAGS, TYPE_TAGS_UPDATE } from 'app/const/Customers';

const keyLocalStore = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.LOCATION_CUSTOMER_LIST);

const CustomerLocations = ({ children }) => {
    const dispatch = useDispatch();
    const [state, dispatchState] = useReducer(reducer, {
        data: [],
        checkedItems: { is_check_all: false, ids: [] },
        isLoading: true,
        totalPage: 1,
        total: 0,
        deleted: 1,
        currentPage: DEFAULT_PAGE,
        refreshScreen: 0,
        refresh: false
    });
    const { customer, location_ids, tagsUpdate, reloadCustomerPage, _handleUpdateLocationList, _handleUpdateUnitList } =
        useContext(CustomerDetailContext);
    const { smsplivo, mdu_tracking } = useSelector(({ auth }) => auth.user?.settings?.addons);

    const { id: customerId } = useParams();
    const refOldLocation = useRef(getLocalStorage(KEY_LOCATION_FILTER_SELECTED)?.location_ids || -1);
    const { t } = useTranslation(['customers']);
    const refStatusBar = useRef(null);
    const isFirstTime = useRef(true);
    const { data: locations, isLoading, refreshScreen, checkedItems } = state;
    const selected = checkedItems.ids;
    const totalSelected = selected.length || 0;
    const totalItem = locations.length || 0;
    const refConfirm = useRef(null);
    const MINIMUM_LOCATION = 1;
    const refButtonSave = useRef(null);
    const abortController = useRef(null);

    let paramsCustomerLocations = getLocalStorage(keyLocalStore);

    if (!paramsCustomerLocations || paramsCustomerLocations.customer_id !== customerId) {
        paramsCustomerLocations = getDefaultParams(REPORT_TYPE.LOCATION_CUSTOMER_LIST);
        paramsCustomerLocations.customer_id = customerId;
        setLocalStorage(keyLocalStore, paramsCustomerLocations);
    }

    useEffect(() => {
        if (!isLoading) dispatchState({ isLoadingSubscription: true });
        _getListLocationCustomer(
            resetPagingLocation({
                params: paramsCustomerLocations || getLocalStorage(keyLocalStore),
                key: keyLocalStore
            })
        );

        return () => {
            handleAbortController(abortController);
        };
    }, []);

    useEffect(() => {
        if (!isLoading && tagsUpdate && !isFirstTime.current) {
            let shouldUpdateTags = false;
            const newLocations = locations.map((location) => {
                if (location.id === tagsUpdate.locationId) {
                    shouldUpdateTags = true;
                    return { ...location, tags: tagsUpdate?.tags || [] };
                }
                return location;
            });
            if (shouldUpdateTags) dispatchState((prevState) => ({ ...prevState, data: newLocations }));
        }
    }, [tagsUpdate]);

    useEffect(() => {
        if (isFirstTime.current || isLoading) return;
        const isUpdateLocation = location_ids && refOldLocation.current !== location_ids;

        const _handleGetLocation = () => {
            if (!isLoading) dispatchState({ isLoadingSubscription: true });
            refOldLocation.current = location_ids;
            _getListLocationCustomer(
                resetPagingLocation({
                    params: paramsCustomerLocations || getLocalStorage(keyLocalStore),
                    key: keyLocalStore
                })
            );
        };

        if (isUpdateLocation || reloadCustomerPage) {
            _handleGetLocation();
        } else if (!isFirstTime.current && location_ids && locations.length) {
            const shouldUpdateState = locations.some((location) => location.id === location_ids);
            if (shouldUpdateState) _handleGetLocation();
        }
    }, [location_ids, reloadCustomerPage]);

    const _handleSyncLocation = (locationsSynced = []) => {
        dispatchState((prevState) => {
            return {
                ...prevState,
                data: locations.map((item) => {
                    const itemSync = locationsSynced.find((location) => location.id === item.id);
                    if (itemSync) {
                        const { messaging_preferences = {}, invoice_addressee, bill_to } = itemSync;
                        return {
                            ...item,
                            sms: messaging_preferences.sms_to,
                            email: messaging_preferences.email_to,
                            address_contact: {
                                ...item.address_contact,
                                address_to: invoice_addressee,
                                bill_to: bill_to
                            }
                        };
                    }
                    return item;
                })
            };
        });
    };

    const _getListLocationCustomer = (data, notShowLoading = false) => {
        handleAbortController(abortController);
        abortController.current = new AbortController();

        if (!notShowLoading) {
            const paramReducer = {};
            paramReducer.checkedItems = { is_check_all: false, ids: [] };
            paramReducer.isLoading = true;
            paramReducer.deleted = data.deleted;
            dispatchState(paramReducer);
        }

        const params = data;
        params.status = params.filterTab;
        params.deleted = params.status;
        delete params?.columns;

        const _success = ({ data, total }) => {
            isFirstTime.current = false;
            dispatchState({
                data: customerLocation(data),
                totalPage: Math.ceil(total / params.limit),
                total,
                isLoading: false,
                refreshScreen: refreshScreen + 1
            });
        };

        const _failed = ({ isAborted = false }) => {
            if (isAborted) return;
            dispatchState({ data: [], isLoading: false });
        };
        clientQuery(
            getListLocationCustomer(customerId),
            {
                data: { ...params, location_ids: location_ids || refOldLocation.current },
                method: 'GET',
                abortController: abortController.current
            },
            _success,
            _failed
        );
    };

    const _handleUpdate = () => {
        _getListLocationCustomer(getLocalStorage(keyLocalStore));
    };

    const onChangeStateCheckedItems = (checkedItems) => {
        dispatchState({ checkedItems });
    };

    const _saveMassageSuccess = () => {
        refStatusBar.current.showStatusBar('show_success', t('customers:save_success'), LIST_STATUS.SUCCESS);
        dispatchState({ checkedItems: { is_check_all: false, ids: [] } });
    };

    const _handleAddLocationSuccess = (newLocation, dataAlert) => {
        if (paramsCustomerLocations.filterTab === TABS_FILTER.ACTIVE_CUSTOMER_LOCATION.value) {
            _getListLocationCustomer(getLocalStorage(keyLocalStore));
        }
        _handleUpdateUnitList(newLocation?.mdu?.units || [], 'create_from_location');
        _handleUpdateLocationList(responseToLocationSelected(newLocation, customerId), 'create');
        _handleShowAlert(dataAlert);
    };

    const _handleUpdateLocationSuccess = (newLocation, dataAlert) => {
        const newLocations = locations.map((item) => {
            if (item.id === newLocation.id) return { ...item, ...customerLocationItem(newLocation) };
            return item;
        });
        dispatchState({ data: newLocations });
        _handleUpdateLocationList(responseToLocationSelected(newLocation, customerId), 'update_single');
        _handleShowAlert(dataAlert);
    };

    const _handleShowAlert = (dataAlert) => {
        if (refStatusBar.current) {
            refStatusBar.current.clearAllStatusBar();
            refStatusBar.current.showStatusBar(dataAlert.id, dataAlert.message, dataAlert.status);
        }
    };

    const _handleAddLocation = () => {
        dispatch(
            openLocation({
                customer,
                customerId,
                onAddLocationSuccess: _handleAddLocationSuccess
            })
        );
    };

    const _handleEditLocation = (location) => {
        dispatch(
            openLocation({
                id: location.id,
                canDelete: location.id && totalItem > 1,
                customer,
                customerId,
                onDeleteLocationSuccess: _handleChangeStatusSuccess,
                onUpdateLocationSuccess: _handleUpdateLocationSuccess,
                onUpdateUnitSuccess: _handleUpdateUnitSuccess
            })
        );
    };

    const _handleUpdateUnitSuccess = (data, type = 'update') => {
        _handleUpdateUnitList(data, type);
    };

    const _handleChangeStatusSuccess = (locationId, messAlert) => {
        _getListLocationCustomer(paramsCustomerLocations, false);
        _handleShowAlert(messAlert);
        _handleUpdateLocationList({ ids: [locationId], deleted: 1 }, 'delete');
    };

    const _handleActionHeader = ({ actionType, columnsTarget, currentValue }) => {
        handleActionHeaderReport({
            actionType,
            reportType: REPORT_TYPE.LOCATION_CUSTOMER_LIST,
            columnsTarget,
            currentValue,
            paramsReport: paramsCustomerLocations,
            callBack: _handleUpdate
        });
    };

    const handleChangeFilter = (params, mode) => {
        if (mode && mode === 'columns')
            dispatchState((prevState) => ({ ...prevState, refreshScreen: prevState.refreshScreen + 1 }));
    };

    const handleDeleteLocation = () => {
        if (locations.length === MINIMUM_LOCATION) {
            const locationLasted = locations.find((item) => item.id === selected.toString());
            _handleShowAlert({
                id: LIST_STATUS.ERROR,
                message: t('customers:can_not_delete_location', { locationName: locationLasted.location_name }),
                status: LIST_STATUS.ERROR
            });
            _handleCloseConfirm();
            return;
        }

        const _handleDeleteSuccess = (response) => {
            let ids = selected || [];
            if (response.error?.items) ids = ids.filter((item) => !response.error.items.includes(item));
            _handleSuccess(response);
            _handleUpdateLocationList({ ids, deleted: 1 }, 'delete');
        };

        clientQuery(
            DELETE_LOCATION_CUSTOMER,
            { data: { ids: selected }, method: 'DELETE' },
            _handleDeleteSuccess,
            _handleFail,
            _handleFinally
        );
    };

    const handleUnDeleteLocation = () => {
        const _handleUnDeleteSuccess = (response) => {
            _handleSuccess(response);
            const locationsUnDelete = [];
            locations.forEach((item) => {
                if (selected.includes(item.id)) {
                    const street1 = item?.address?.service?.line1 || '';
                    const street2 = item?.address?.service?.line2 || '';
                    const city = item?.service_address_city || '';
                    const state = item?.service_address_state || '';
                    const zip = item?.service_address_zip || '';

                    locationsUnDelete.push({
                        id: item.id,
                        name: item.location_name,
                        location_note: item.notes,
                        deleted: 0,
                        address: displayLocation({ street1, street2, city, state, zip, withoutHTML: true }),
                        street1,
                        street2,
                        city,
                        state,
                        zip,
                        bill_to: item?.bill_to || '',
                        billing_address_1: item?.billing_address_1 || '',
                        billing_address_2: item?.billing_address_2 || '',
                        billing_city: item?.billing_address_city || '',
                        billing_state: item?.billing_address_state || '',
                        billing_zip: item?.billing_address_zip || '',
                        lat: item?.address_lat || '',
                        lng: item?.address_lng || '',
                        tags: item?.tags || []
                    });
                }
            });

            _handleUpdateLocationList(locationsUnDelete, 'un_delete');
        };
        clientQuery(
            UN_DELETE_LOCATION,
            { data: { ids: selected, customer_id: customerId }, method: 'PUT' },
            _handleUnDeleteSuccess,
            _handleFail,
            _handleFinally
        );
    };

    const _handleSuccess = ({ message }) => {
        _handleChangeStatusSuccess(null, {
            id: LIST_STATUS.SUCCESS,
            message: message || t('delete_successfully'),
            status: LIST_STATUS.SUCCESS
        });
    };

    const _handleFail = ({ message }) => {
        refStatusBar.current.showStatusBar(LIST_STATUS.ERROR, message || t('customers:failed'), LIST_STATUS.ERROR);
    };

    const _handleOpenConfirm = () => {
        refConfirm.current._open();
    };

    const _handleCloseConfirm = () => {
        refConfirm.current._close();
    };

    const _handleFinally = () => {
        refButtonSave.current?.removeLoading();
        _handleCloseConfirm();
    };

    const _handleSaveTags = (tags, id, typeTag = 1) => {
        const dataTags = tags?.map((item) => item.name) || [];
        const typeUpdate =
            typeTag === TYPE_TAGS.TAG_INVOICE ? TYPE_TAGS_UPDATE.TAG_INVOICE : TYPE_TAGS_UPDATE.TAG_LOCATION;
        _handleUpdateLocationList({ id, tags: _handleSortTags(dataTags) }, 'update_single');
        clientQuery(
            updateTagsLocation(id),
            { data: { tags: dataTags, type: typeUpdate }, toFormData: false, method: 'PUT' },
            _handleSaveTagsSuccess(tags, id, typeUpdate)
        );
    };

    const _handleSaveTagsSuccess = (tags, id, typeUpdate) => {
        const newData = locations.map((item) => {
            if (item.id === id)
                return { ...item, [typeUpdate === TYPE_TAGS_UPDATE.TAG_INVOICE ? 'invoice_tags' : 'tags']: tags };
            return item;
        });
        dispatchState({ data: newData });
    };

    return (
        <>
            <div className="wrapper-columns">
                {children}
                <div className="container-print locations-page contents-pages gap-8">
                    <div className="wrap-tables flex-column relative">
                        <StatusBar ref={refStatusBar} />
                        <LocationActions
                            ids={selected}
                            totalSelected={totalSelected}
                            handleUpdate={_handleUpdate}
                            handleSaveMassageSuccess={_saveMassageSuccess}
                            params={paramsCustomerLocations}
                            customerId={customerId}
                            totalItems={state.total || 0}
                            refresh={refreshScreen}
                            isActiveSMS={smsplivo}
                            onHandleChangeFilter={handleChangeFilter}
                            onHandleDelete={_handleOpenConfirm}
                            onHandleUnDelete={handleUnDeleteLocation}
                            onAddLocation={_handleAddLocation}
                            checkedItems={checkedItems}
                        />
                        <GdGridView
                            isLoading={isLoading}
                            content={locations}
                            msgEmpty={t('customers:no_locations')}
                            classTable="table-multi-column has-checkbox has-footer scrolls-x"
                            checkedItems={state.checkedItems}
                            fileTranslation={'report'}
                            onChangeStateCheckedItems={onChangeStateCheckedItems}
                            onOpenLocation={_handleEditLocation}
                            showCheckBox
                            {...getGridColumnsCustomerLocation(
                                paramsCustomerLocations?.columns,
                                { isShowSms: smsplivo, isShowUnit: mdu_tracking },
                                paramsCustomerLocations?.order,
                                paramsCustomerLocations?.filterTab
                            )}
                            handleClickHeader={_handleActionHeader}
                            isScroll
                            onHandleSaveTags={_handleSaveTags}
                        />
                        <ReportPagination
                            reportType={REPORT_TYPE.LOCATION_CUSTOMER_LIST}
                            totalPage={state.totalPage || 1}
                            onSelect={_handleUpdate}
                            isCustomerPage
                            classTabList="btn-item bg-white ml-0"
                            classWrapper="tabs"
                        />
                        <GDModalWarning
                            ref={refConfirm}
                            title={t('customers:delete_location')}
                            description={t('customers:confirm_delete_location')}
                            footer={
                                <div className="footer-modal justify-end">
                                    <div className="v2-btn-default --transparent" onClick={_handleCloseConfirm}>
                                        {t('customers:cancel')}
                                    </div>
                                    <ButtonSave
                                        ref={refButtonSave}
                                        onSave={handleDeleteLocation}
                                        title={t('customers:yes')}
                                    />
                                </div>
                            }
                        />
                    </div>
                </div>
            </div>
            <LocationRealtime onUpdate={_handleSyncLocation} />
        </>
    );
};

export default CustomerLocations;
