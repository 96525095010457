import React from 'react';

const EventContentHoliday = ({ title = '', scheduleCount = 1 }) => {
    return (
        <div className="txt-holiday" style={{ '--schedule-number': scheduleCount }}>
            {title}
        </div>
    );
};

export default EventContentHoliday;
