import GdLazyImage from 'app/components/lazyimage';
import { reducer } from 'app/const/Reducer';
import queryStrings from 'query-string';
import { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { ACCESS_TOKEN, KEY_MODAL_SMS_CHARGE_FAILED, LOGIN_BY_TOKEN_TYPES, STATUS_ACCOUNT } from 'app/const/App';
import { CALENDAR } from 'app/const/Route';
import { userLoginByTokenRequest } from 'common/redux/actions/authAction';
import { getLocalStorageValue, removeLocalStorage } from 'common/utils/LocalStorageUtils';

import LoadingFullPage from 'app/components/loading';
import { LOGIN_ADMIN, LOGIN_CLASSIC, LOGIN_INTEGRATION } from 'app/const/Api';
import { REDIRECT_MAP, SETTINGS_PLANS_REDIRECT_CODE } from 'app/const/Auth';
import { addBranchPath } from 'app/const/Branch';
import { LINK_CDN_IMG } from 'app/const/URL';
import { restoreOptionJobCalendar } from 'app/modules/calendar/ultil/Calendar';
import ErrorPage from 'app/modules/error';
import { mixpanelLogin } from 'app/modules/mixpanel/MixpanelLogin';
import { handleTrackingEvent } from 'common/utils/MixpanelUtils';
import { AuthBanner } from '../components/AuthBanner';
import TwoStepAuthentication from '../twoStepAuthentication';

function LoginByToken({ onReload = () => {} }) {
    const dispatch = useDispatch();
    const { t } = useTranslation('auth');
    const { search } = useLocation();
    const { token, type } = useParams();
    const history = useHistory();
    const [state, dispatchState] = useReducer(reducer, { message: '', isLoading: true, isShow2FA: false, list2FA: [] });
    const {
        message: finalMessage,
        isLoading: finalIsLoading,
        isShow2FA: finalIsShow2FA,
        list2FA: finalList2FA
    } = state;

    const isValidType = [
        LOGIN_BY_TOKEN_TYPES.ACCESS,
        LOGIN_BY_TOKEN_TYPES.ACCESS_CLOSEIO,
        LOGIN_BY_TOKEN_TYPES.CLASSIC
    ].includes(type);
    const refData = useRef({});

    const _loginSuccess = (response) => {
        handleTrackingEvent(mixpanelLogin({ profile: response.profile, company: response.company, isHaveToken: true }));
        const redirectCode =
            response.profile?.plan?.status === STATUS_ACCOUNT.EXPIRED
                ? SETTINGS_PLANS_REDIRECT_CODE
                : response.profile?.redirect;

        const { isSignupSuccess } = queryStrings.parse(search) || {};
        history.push({
            pathname: addBranchPath(REDIRECT_MAP[redirectCode] || CALENDAR),
            state: { isSignupSuccess: parseInt(isSignupSuccess) === 1 }
        });
        restoreOptionJobCalendar();
    };

    const _loginFail = ({ message, list_2fa = [], redirect_2fa = false }) => {
        if (redirect_2fa) {
            dispatchState((prev) => ({ ...prev, list2FA: list_2fa, isShow2FA: true, isLoading: false }));
        } else {
            dispatchState({ message: message || t('link_is_invalid'), isLoading: false });
        }
    };

    const _getUrl = () => {
        switch (type) {
            case LOGIN_BY_TOKEN_TYPES.ACCESS:
                return LOGIN_ADMIN;
            case LOGIN_BY_TOKEN_TYPES.ACCESS_CLOSEIO:
                return LOGIN_INTEGRATION;
            case LOGIN_BY_TOKEN_TYPES.CLASSIC:
                return LOGIN_CLASSIC;
            default:
                return '';
        }
    };

    useEffect(() => {
        removeLocalStorage(KEY_MODAL_SMS_CHARGE_FAILED);
        if (type === LOGIN_BY_TOKEN_TYPES.CLASSIC && getLocalStorageValue(ACCESS_TOKEN)) {
            onReload();
            return;
        }
        if (isValidType) {
            refData.current = { token, url: _getUrl() };
            dispatch(userLoginByTokenRequest({ token, url: _getUrl() }, _loginSuccess, _loginFail));
        }
    }, []);

    const _handle2FASuccess = () => {
        dispatch(userLoginByTokenRequest(refData.current, _loginSuccess, _loginFail));
    };

    if (finalIsLoading) return <LoadingFullPage />;

    return finalIsShow2FA ? (
        <div className="gorilladesk auth-page">
            <div className="wrapper auth-container">
                <AuthBanner />
                <div className="main-login mt-10">
                    <TwoStepAuthentication data={finalList2FA} handleSuccess={_handle2FASuccess} />

                    <p className="mascot">
                        <GdLazyImage
                            src={`${LINK_CDN_IMG}half-gorilla.png`}
                            width={165}
                            height={150}
                            alt={t('common:gorilladesk')}
                        />
                    </p>
                </div>
            </div>
        </div>
    ) : (
        <ErrorPage isBack={isValidType} errorMessage={finalMessage} showContactSupport={false} />
    );
}

export default LoginByToken;
