import { CONTENT_COMMON_TEMPLATE_VARIABLE, CONTENT_VARIABLE_COMPANY } from 'app/const/Settings';
import { formatTime } from 'common/utils/TimeUtils';
import { checkCrewUser } from './userCompany';

export const customersService = (data) =>
    data.map((item) => {
        return {
            id: item.id,
            name: item.name,
            length: formatTime(item.length),
            color_code: item.color_code,
            frequency: item.frequency
        };
    });

export const customersSchedules = (data) =>
    data.map((item) => {
        return {
            id: item.id,
            name: item.name,
            nickname: item.nickname,
            assignee: item.assignee,
            groups: item.groups,
            start_address: item.start_address,
            end_address: item.end_address,
            total_active_jobs: item.total_active_jobs,
            has_recurring_jobs: item.has_recurring_jobs
        };
    });

export const customersSystem = (data) =>
    data.map((item) => {
        return {
            id: item.id,
            template: item.name,
            status: item.active,
            alway_active: item.alway_active,
            plan: item.upgrade
        };
    });

/* This function is used to replace variable with value from company and
CONTENT_COMMON_TEMPLATE_VARIABLE. */
export const handlePreviewTemplate = (value, valueCompany, variableTemplate) => {
    let content = value;

    /* This is replace variable with value from company. */
    CONTENT_VARIABLE_COMPANY.forEach((item) => {
        if (valueCompany[item]) content = content.replace(new RegExp('\\' + `${item}`, 'g'), valueCompany[item]);
    });

    /* This is replace variable with value from CONTENT_COMMON_TEMPLATE_VARIABLE. */
    for (const key in CONTENT_COMMON_TEMPLATE_VARIABLE) {
        if (Object.hasOwnProperty.call(CONTENT_COMMON_TEMPLATE_VARIABLE, key)) {
            content = content.replace(new RegExp('\\' + `${key}`, 'g'), CONTENT_COMMON_TEMPLATE_VARIABLE[key]);
        }
    }

    /* This is replace variable with value from variableTemplate. */
    for (const key in variableTemplate) {
        if (Object.hasOwnProperty.call(variableTemplate, key)) {
            content = content.replace(new RegExp('\\' + `${key}`, 'g'), variableTemplate[key]);
        }
    }

    return content;
};

export const convertHtmlToPlaintext = (html) => {
    html = html.replace(/<style([\s\S]*?)<\/style>/gi, '');
    html = html.replace(/<script([\s\S]*?)<\/script>/gi, '');
    html = html.replace(/<\/div>/gi, '\n');
    html = html.replace(/<\/li>/gi, '\n');
    html = html.replace(/<li>/gi, '  *  ');
    html = html.replace(/<\/ul>/gi, '\n');
    html = html.replace(/<\/p>/gi, '\n');
    html = html.replace(/<br\s*[\/]?>/gi, '\n');
    html = html.replace(/<[^>]+>/gi, '');
    return html;
};

/**
 * It takes an array of objects and returns an array of objects with the same objects grouped by a
 * group_name
 * @param data - the data you want to convert
 */
export const convertTemplateToGroup = (data) => {
    let tempGroup = '';
    const result = [];

    if (!Array.isArray(data)) throw new Error('"data" must be an array.');

    data.forEach((element, index) => {
        const indexGroup = result.findIndex((item) => item.idGroup === element.group_name);

        if (indexGroup >= 0) {
            result[indexGroup]['children'].push(element);
        } else {
            const groupName = element.group_name;

            if (groupName) {
                tempGroup = '';
                result.push({ idGroup: groupName, group_name: groupName, children: [element] });
            } else {
                if (tempGroup) {
                    const indexGroup = result.findIndex((item) => item.idGroup === tempGroup);
                    if (indexGroup >= 0) result[indexGroup]['children'].push(element);
                } else {
                    tempGroup = `none_group_${index}`;
                    result.push({ idGroup: `none_group_${index}`, group_name: '', children: [element] });
                }
            }
        }
    });

    return result;
};

/**
 * It takes a sidebar data structure and an object with updated data, and returns a new sidebar data
 * structure with the updated data
 * @param dataSidebar - The data that is currently in the sidebar.
 * @param dataUpdate - The data of template to be updated.
 * @returns - dataSidebar
 */
export const updateTemplateGroup = (dataSidebar, dataUpdate = {}) => {
    if (typeof dataUpdate !== 'object') throw new Error('dataUpdate must be an object.');

    let result = [...dataSidebar];
    const haveGroup = result.some((item) => item.idGroup === dataUpdate.group_name);

    result = result.map((item) => {
        if (item.idGroup === dataUpdate.group_name) {
            const haveTemplate = (item.children || []).some((item) => item.id === dataUpdate.id);
            if (haveTemplate) {
                return {
                    ...item,
                    children: (item.children || []).map((child) => {
                        if (child.id === dataUpdate.id) return { ...child, ...dataUpdate };
                        return child;
                    })
                };
            } else {
                return { ...item, children: [...(item.children || []), dataUpdate] };
            }
        }
        return { ...item, children: [...(item.children || []).filter((item) => item.id !== dataUpdate.id)] };
    });

    if (!haveGroup)
        result.push({
            idGroup: dataUpdate.group_name || `none_group_${Date.now()}`,
            group_name: dataUpdate.group_name || '',
            children: [dataUpdate]
        });
    return result;
};

/**
 * It takes two objects, one of which is an array of objects, and returns a new array of objects
 * @param dataSidebar - the data structure of the sidebar
 * @param dataTemplate - the object that contains the data of the template to be added.
 * - If the group exists, the template is added to the group.
 * - If the group does not exist, a new group is created and the template is added to the group.
 * @returns - dataSidebar
 */
export const addTemplateToGroup = (dataSidebar, dataTemplate) => {
    if (typeof dataTemplate !== 'object') throw new Error('dataUpdate must be an object.');
    const result = [...dataSidebar];
    const indexGroup = result.findIndex((item) => item.idGroup === dataTemplate.group_name);

    if (indexGroup >= 0) {
        result[indexGroup] = { ...result[indexGroup], children: [...result[indexGroup].children, dataTemplate] };
    } else {
        result.push({
            idGroup: dataTemplate.group_name || `none_group_${Date.now()}`,
            group_name: dataTemplate.group_name || '',
            children: [dataTemplate]
        });
    }
    return result;
};

export const matchPermissionsUser = (listKey = {}, permissions = [], groupPermissions = {}) => {
    const newPermissions = { ...listKey };
    permissions.forEach((item) => {
        const groupItem = groupPermissions[item];
        if (groupItem) groupItem.value = true;
    });
    Object.keys(groupPermissions).forEach((permission) => {
        const permissionObject = groupPermissions[permission];
        const group = permissionObject.group;
        newPermissions[group] = [...newPermissions[group], permissionObject];
    });
    return newPermissions;
};

export const convertUserSettingToRetrieve = (userSetting) => {
    if (checkCrewUser(userSetting.type)) return userSetting;
    const user = userSetting.user || {};

    return {
        id: userSetting.id,
        avatar: user.avatar,
        first_name: user.first_name,
        full_name: user.full_name,
        last_name: user.last_name
    };
};

export const getPathNameUrl = (url) => new URL(url).pathname || '';

export const getTitleTemplate = ({ title = '', value }) => {
    return title?.[value] || title || '';
};

export const getTextColorFromBackground = (hex) => {
    // Remove the hash at the start if it's there
    hex = hex.replace('#', '');
    // Parse the hex color code
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    // Calculate the brightness (YIQ formula)
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    // Return white for dark backgrounds, black for light backgrounds
    return yiq >= 128 ? '#000000' : '#FFFFFF';
};
