/* eslint-disable no-unused-vars */
import moment from 'moment';
import { convertTimeToNumber } from './TimeUtils';
import { JOB_UNCONFIRMED } from 'app/const/Job';

export const getNewDateWithDelay = (date, delay) => {
    if (!delay) {
        return date;
    }
    const { value, type } = delay;
    return moment(date).utc().add(type, value).toISOString();
};

export const prepareCreateJob = (state) => {
    const { customer_id: stateCustomerId, schedule: finalSchedule, start: dateStart, isFromJobPlan } = state;

    const momentStart = moment(dateStart).utc();
    const numberTimeStart = convertTimeToNumber(momentStart.format('HH'), momentStart.format('mm'));

    return {
        customer: state.customer || null,
        customer_id: stateCustomerId || '',
        location_id: state.location_id || null,
        location: state.location || {},
        isAddFromDetail: state.isAddFromDetail || false,
        start: dateStart,
        timeStart: dateStart,
        repeat: state.repeat || 0,
        recurrence: state.recurrence || {
            offset: {},
            summary: 'Once'
        },
        schedule: finalSchedule ? (Array.isArray(finalSchedule) ? finalSchedule : [finalSchedule]) : [],
        exception: state.exception || {
            options: {
                first: null,
                second: null,
                third: null
            }
        },
        length: state.length || 0,
        invoice: state.invoice || null,
        estimate: state.estimate || null,
        isFromEstimate: state.isFromEstimate || false,
        time_window: state.time_window || {
            id: '',
            start: numberTimeStart,
            end: numberTimeStart + 15
        },
        changedTimeWindow: false,
        estimate_items: state.estimate_items,
        isServicePlanForm: false,
        isLoadingServicePlan: false,
        servicePlan: [],
        isFromJobPlan,
        serviceData: state.serviceData || {},
        changeService: state.changeService || 0,
        status: state.status || JOB_UNCONFIRMED,
        commission_selected: state.commission_selected || [],
        crew_ids: state.crew_ids || [],
        serviceItemId: state.serviceItemId || '',
        isFetchedServiceDetail: state.isFetchedServiceDetail || false,
        documents: state.documents || [],
        materials: state.materials || [],
        always_confirmed: state.always_confirmed || 0,
        buildings: state.buildings || [],
        isInitJob: state.isInitJob || false,
        notify_tech: state.notify_tech || false,
        notify_customer: state.notify_customer || {},
        job_state: state.job_state || {}
    };
};
