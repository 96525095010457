import GdLazyImage from 'app/components/lazyimage';
import IconArrow from 'assets/icon/IconArrow';
import IconDropUpDown from 'assets/icon/IconDropUpDown';
import classNames from 'classnames';
import useClickOutside from 'common/hooks/useClickOutside';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HeaderActions from './HeaderActions';
import HeaderPhoneList from './HeaderPhoneList';
import LoadingUserList from 'app/components/loading/sms/LoadingUserList';
import { CUSTOMERS_ACCOUNT } from 'app/const/Route';
import { Link } from 'react-router-dom';
import IconPlus from 'assets/icon/IconPlus';
import IconAssign from 'assets/icon/IconAssign';
import { addBranchPath } from 'app/const/Branch';

function DetailHeader({
    onGoBack,
    customer,
    onClose,
    phones,
    users,
    phoneLoading,
    onBookmark = () => {},
    handleSelectPhone = () => {},
    handleSelectedUsers = () => {},
    isUnknow,
    onCreateLead = () => {},
    onAssignLead = () => {},
    isShowHeader = false
}) {
    const { t } = useTranslation(['header']);

    const [dropdownRef, isShow, setIsShow] = useClickOutside(false);
    const [selected, setSelected] = useState([]);

    const { selected: finalUsersSelected = [], list: finalListUser = [] } = users;
    const numberListUser = finalListUser.length;

    useEffect(() => {
        if (numberListUser) setSelected(finalUsersSelected);
    }, [finalUsersSelected]);

    const handleOnClick = (action, value) => {
        switch (action) {
            case 'check_item': {
                const { item, checked } = value;

                checked ? setSelected(selected.filter((i) => i.id !== item.id)) : setSelected([...selected, item]);
                break;
            }
            case 'check_all': {
                value ? setSelected([]) : setSelected(finalListUser);
                break;
            }
            case 'apply': {
                handleSelectedUsers(selected);
                setIsShow(!isShow);
                break;
            }
            default:
                break;
        }
    };

    function _bookmarkSms() {
        _handleCloseAll();
        onBookmark();
    }

    function _handleCloseAll() {
        onClose();
        onGoBack();
    }

    function _renderListUser() {
        if (!numberListUser) {
            return (
                <li className={'items'}>
                    <span className="name">{'Not found'}</span>
                </li>
            );
        }

        return finalListUser.map((item) => {
            const { id: userId, full_name: userFullName = '' } = item;

            const checked = selected.some((i) => i.id === userId);

            return (
                <li
                    key={userId}
                    className={classNames('items', {
                        active: checked
                    })}
                    onClick={() =>
                        handleOnClick('check_item', {
                            item,
                            checked
                        })
                    }
                >
                    <div className="check-items --no-affect">
                        <input type="checkbox" checked={checked} readOnly />
                        <div className="item-checkbox">
                            <label htmlFor={`check-person-${userId}`}></label>
                        </div>
                    </div>
                    <div className="images">
                        <div className="avt-img">
                            <GdLazyImage src={`${item?.avatar}`} alt={`${userFullName}}`} />
                        </div>
                    </div>
                    <span className="name">{userFullName}</span>
                </li>
            );
        });
    }

    function _renderListUserSelected() {
        return finalUsersSelected.map((item, index) => {
            if (index > 3) {
                return false;
            }
            const userFullName = item.full_name || '';

            return (
                <li key={item.id} className="item-user">
                    <div className="avt-img">
                        <GdLazyImage src={`${item.avatar}`} alt={`${userFullName}}`} />
                    </div>
                    <p className="item-user__name">{userFullName}</p>
                </li>
            );
        });
    }

    function _renderBoxUsers() {
        if (phoneLoading && !isUnknow) {
            return <LoadingUserList />;
        }

        return (
            <div ref={dropdownRef} className={classNames('v2-dropdown wrap-action wrap-list-user', { active: isShow })}>
                <div onClick={() => setIsShow(!isShow)} className="dropbtn items">
                    <ul className="d-flex">
                        {_renderListUserSelected()}
                        {finalUsersSelected.length > 3 && (
                            <li className="item-user --more">+{finalUsersSelected.length - 3}</li>
                        )}
                    </ul>
                    <div className="arrow">
                        <IconDropUpDown />
                    </div>
                </div>
                {isShow && (
                    <div className="v2-dropdown__menu dropdown-list-friends">
                        <div className="wrap-title-list">
                            <span onClick={() => handleOnClick('check_all', selected.length === numberListUser)}>
                                {t(`header:select_all`)}
                            </span>
                            <div className="v2-btn-main">
                                <span onClick={() => handleOnClick('apply')}>{t('apply')}</span>
                            </div>
                        </div>
                        <ul className="list-user scrolls">{_renderListUser()}</ul>
                    </div>
                )}
            </div>
        );
    }

    const _renderCustomer = () => {
        if (isUnknow) {
            return (
                <a className="title-search cursor-pointer">
                    <div className="avt">{customer?.avatar}</div>
                    <div className="name">Unknown {customer.default_phone}</div>
                </a>
            );
        }
        const customerId = customer.id;
        return (
            <Link
                to={addBranchPath(`${CUSTOMERS_ACCOUNT}/${customerId}`)}
                className={classNames('title-search cursor-pointer', { 'is-disable': !customerId })}
            >
                <div className="avt">{customer?.avatar}</div>
                <div className="name">{customer?.full_name}</div>
            </Link>
        );
    };

    if (!isShowHeader) {
        if (isUnknow) {
            return null;
        }
        return (
            <div className="header-noti">
                <div className="header-title">
                    <div className="info">
                        <HeaderPhoneList
                            data={phones}
                            onClick={handleOnClick}
                            onSelectPhone={handleSelectPhone}
                            loading={phoneLoading}
                        />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="header-noti">
            <div className="header-title">
                <div onClick={() => onGoBack(true)} className="v2-btn-default --icon-sm --transparent btn-plus">
                    <IconArrow isPrev />
                </div>
                <div className="info">
                    <div className="wrap-title">
                        {_renderCustomer()}
                        <HeaderActions
                            customer={customer}
                            onClose={onClose}
                            onBookmark={_bookmarkSms}
                            onCancel={_handleCloseAll}
                            isUnknow={isUnknow}
                        />
                    </div>
                    {!isUnknow ? (
                        <HeaderPhoneList
                            data={phones}
                            onClick={handleOnClick}
                            onSelectPhone={handleSelectPhone}
                            loading={phoneLoading}
                        />
                    ) : (
                        <HearUnknow onCreateLead={onCreateLead} onAssignLead={onAssignLead} />
                    )}
                </div>
            </div>
            {_renderBoxUsers()}
        </div>
    );
}

const HearUnknow = ({ onCreateLead, onAssignLead }) => {
    return (
        <div className="wrap-lead">
            <div
                onClick={onCreateLead}
                className="v2-btn-default has-icon btn-bg-purple --sm svg-purple js-create-lead"
            >
                <IconPlus isCircle />
                Create Lead
            </div>
            <div onClick={onAssignLead} className="v2-btn-default has-icon btn-bg-purple --sm js-assign-lead">
                <IconAssign />
                Assign Lead
            </div>
        </div>
    );
};

export default DetailHeader;
