import { getTextColorFromBackground } from 'app/modules/settings/utils';
import { EVENT_TYPES, TASK_BORDER_DEFAULT, TASK_CLASS_NAME, TASK_TEXT_DEFAULT } from '../const';

const DEFAULT_DATA_HOLIDAY_EVENT = {
    allDay: true,
    classNames: TASK_CLASS_NAME + ' is-holiday',
    borderColor: TASK_BORDER_DEFAULT,
    textColor: TASK_TEXT_DEFAULT,
    type: EVENT_TYPES.HOLIDAY
};

const convertEvent = (event, country = {}, schedules = []) => {
    const resourceId = schedules[0]?.id || '1';
    const countryColor = country.color;
    return {
        ...event,
        ...DEFAULT_DATA_HOLIDAY_EVENT,
        id: country.name + '_' + event.start,
        title: event.summary,
        schedule: schedules[0],
        resourceId,
        allDay: true,
        color: countryColor,
        textColor: countryColor && getTextColorFromBackground(countryColor),
        countryColor: countryColor
    };
};

const convertEventBackground = (event = {}, country = {}, schedules = [], isMonth = false) => {
    const result = { start: event.start, end: event.end, display: 'background' };
    if (country.color) result['color'] = country.color;
    schedules.length > 1
        ? (result['resourceIds'] = schedules.map((item) => item.id))
        : (result['resourceId'] = schedules[0]?.id || '1');
    // Display all day in month view
    result['allDay'] = isMonth;
    result['resource'] = schedules[0];
    result['schedule'] = schedules[0];
    result['summary'] = event.summary;
    return result;
};

export const handleConvertHoliday = ({ data = [], schedules = [], isMonthView = false }) => {
    const normalEvents = [];
    const backgroundEvents = [];
    const tempObj = {};

    data.forEach(({ country = {}, events = [] }) => {
        if (country.value && events.length) {
            events.forEach((event) => {
                // Normal events display on top of calendar with allDay property
                normalEvents.push(convertEvent(event, country, schedules));
                // Background events display whole day with background color
                if (!tempObj[event.start]) {
                    tempObj[event.start] = true;
                    backgroundEvents.push(convertEventBackground(event, country, schedules, isMonthView));
                }
            });
        }
    });
    return [...backgroundEvents, ...normalEvents];
};
