import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';

import ButtonSave from 'app/components/button/ButtonSave';
import StatusBar from 'app/components/status/statusbar';
import { ADDONS_SERVICE_PLAN, SETTINGS_SERVICE_PLAN } from 'app/config/routes';
import { SETTINGS_GET_LIST_SERVICE_PLAN, getServicePlanDetail } from 'app/const/Api';
import { addBranchPath } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import { LIST_DROPDOWN_SERVICE_PLAN_TYPE, PENDING_SERVICE_KEY, VALIDATE_SERVICE_PLAN } from 'app/const/Settings';
import ErrorPage from 'app/modules/error';
import IconPlus from 'assets/icon/IconPlus';
import IconServiceTemplate from 'assets/icon/IconServiceTemplate';
import { clientQuery } from 'common/utils/ApiUtils';
import { showStatusBar } from 'common/utils/FunctionUtils';
import { isActiveFeature } from 'common/utils/PermissionUtils';
import MainHeaderSettings from '../components/MainHeaderSettings';
import ItemService from './ItemService';
import ListService from './ListService';
import LoadingServicePlanDetail from './LoadingServicePlanDetail';

const SettingAddServicePlan = () => {
    const { t } = useTranslation('setting');
    const servicePlanAddon = useSelector(({ auth }) => auth.user.settings.addons.service_plan);
    const { id: servicePlanId } = useParams();
    const history = useHistory();
    const [state, dispatchState] = useReducer(reducer, {
        serviceName: '',
        serviceSelected: null,
        pendingServices: [],
        isLoading: false
    });
    const {
        pendingServices: finalPendingServices,
        isLoading: finalIsLoading,
        serviceSelected: finalServiceSelected,
        serviceName: finalServiceName
    } = state;

    const refServicePlanName = useRef(null);
    const refButtonSave = useRef(null);
    const refService = useRef(null);
    const refStatusBar = useRef(null);

    const havePermission = !!servicePlanAddon && isActiveFeature(ADDONS_SERVICE_PLAN);

    useEffect(() => {
        if (!havePermission) return;
        if (servicePlanId) _getServiceDetail();
    }, [servicePlanId]);

    const _getServiceDetail = () => {
        dispatchState((prev) => ({ ...prev, isLoading: true }));
        const _handleSuccess = ({ data }) => {
            const { name, services = [] } = data || {};
            const serviceSelected = {};
            const pendingServices = [];
            services.forEach((item) => {
                const { id, name, delay } = item || {};
                if (!delay) {
                    serviceSelected['id'] = id;
                    serviceSelected['name'] = name;
                } else {
                    pendingServices.push({ ...item, service_id: id });
                }
            });
            dispatchState((prev) => ({
                ...prev,
                serviceName: name || '',
                serviceSelected,
                pendingServices,
                isLoading: false
            }));
        };
        const _handleFail = ({ message }) => {
            showStatusBar({ id: 'get_detail_wrong', message, refAlert: refStatusBar });
            dispatchState((prev) => ({ ...prev, isLoading: false }));
        };

        clientQuery(getServicePlanDetail(servicePlanId), { data: {}, method: 'GET' }, _handleSuccess, _handleFail);
    };

    const _handleAddService = () => {
        dispatchState((prev) => ({
            ...prev,
            pendingServices: [
                ...prev.pendingServices,
                { delay: { value: 1, type: LIST_DROPDOWN_SERVICE_PLAN_TYPE[0].id }, service_id: 0, id: Date.now() }
            ]
        }));
    };

    const _getErrorMessage = ({ name = '' }) => {
        return t('common:can_not_be_blank', { name: t(name) });
    };

    const _handleSave = () => {
        const planName = refServicePlanName.current?.value?.trim();
        const serviceId = refService.current?._getValue();
        const msgError = [];
        if (!planName) msgError.push(_getErrorMessage({ name: VALIDATE_SERVICE_PLAN.PLAN_NAME }));
        if (!serviceId) msgError.push(_getErrorMessage({ name: VALIDATE_SERVICE_PLAN.SERVICE }));
        if (!!finalPendingServices.length && finalPendingServices.some((item) => !item.service_id))
            msgError.push(_getErrorMessage({ name: VALIDATE_SERVICE_PLAN.PENDING_SERVICES }));
        if (!!msgError.length) {
            showStatusBar({ id: 'validate_error', message: msgError, refAlert: refStatusBar });
            _removeLoading();
            return;
        }

        const _handleSuccess = () => {
            history.push(addBranchPath(SETTINGS_SERVICE_PLAN));
        };
        const _handleFail = ({ message }) => {
            showStatusBar({ id: 'save_fail', message, refAlert: refStatusBar });
            _removeLoading();
        };
        clientQuery(
            SETTINGS_GET_LIST_SERVICE_PLAN,
            {
                data: {
                    id: servicePlanId || undefined,
                    name: planName,
                    service_id: refService.current?._getValue(),
                    pending_services: finalPendingServices.map((item) => ({
                        delay: item.delay,
                        service_id: item.service_id
                    }))
                },
                method: servicePlanId ? 'PUT' : 'POST',
                toFormData: false
            },
            _handleSuccess,
            _handleFail
        );
    };

    const _removeLoading = () => {
        refButtonSave.current?.removeLoading();
    };

    const _handleDeletePendingService = (value) => {
        dispatchState((prev) => ({
            ...prev,
            pendingServices: prev.pendingServices.filter((item) => item.id !== value)
        }));
    };

    const _handleChangeDataPendingService = (id, value, keyUpdate) => {
        dispatchState((prev) => ({
            ...prev,
            pendingServices: prev.pendingServices.map((item) => {
                if (item.id === id) {
                    if (keyUpdate === PENDING_SERVICE_KEY.SERVICE_ID) return { ...item, [keyUpdate]: value };
                    return { ...item, delay: { ...item.delay, [keyUpdate]: value } };
                }
                return item;
            })
        }));
    };

    if (!havePermission) return <ErrorPage errorMessage={t('auth:no_permission_access')} />;

    return (
        <Fragment>
            <MainHeaderSettings />
            <div className="wrapper-columns">
                <div className="contents-pages service-edit c-job-details --service-plan">
                    <div className="wrap-content-service wrapper-box-edit">
                        <div className="content-services">
                            <div className="content-services__right scrolls">
                                <StatusBar ref={refStatusBar} />
                                <div className="dashboard-job-detail">
                                    {finalIsLoading ? (
                                        <LoadingServicePlanDetail />
                                    ) : (
                                        <div className="container-setting-center">
                                            <div className="header-modal">
                                                <h3 className="name-tabs">{t('service_plan')}</h3>
                                            </div>
                                            <div className="new-service schedule-wrapper form-tabpane-details">
                                                <div className="details-job">
                                                    <div className="rows">
                                                        <div className="txt">
                                                            <IconServiceTemplate />
                                                            <span className="txt-ellipsis">{t('plan_name')}</span>
                                                        </div>
                                                        <div className="details">
                                                            <input
                                                                ref={refServicePlanName}
                                                                className="field-input"
                                                                type="text"
                                                                defaultValue={finalServiceName}
                                                                placeholder={t('plan_name')}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="rows row-has-line border-top-border-color-grey">
                                                        <ListService
                                                            ref={refService}
                                                            serviceSelected={finalServiceSelected}
                                                        />
                                                    </div>
                                                    {finalPendingServices.map((item) => (
                                                        <ItemService
                                                            key={item.id}
                                                            item={item}
                                                            onChangeData={_handleChangeDataPendingService}
                                                            onDelete={_handleDeletePendingService}
                                                        />
                                                    ))}
                                                    <div className="rows row-has-line border-top-border-color-grey">
                                                        <div
                                                            className="v2-btn-default has-icon"
                                                            onClick={_handleAddService}
                                                        >
                                                            <IconPlus />
                                                            {t('add_service')}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="form-footer">
                            <div className="form-footer__action">
                                <Link to={addBranchPath(SETTINGS_SERVICE_PLAN)} className="v2-btn-default">
                                    {t('common:close')}
                                </Link>
                                <ButtonSave ref={refButtonSave} disabled={finalIsLoading} onSave={_handleSave} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default SettingAddServicePlan;
