import { ADDONS_VOIP_ACTIVE_USER, ADDONS_VOIP_AUTO_ATTENDANT } from './addons';
import IconMicrophone from 'assets/icon/IconMicrophone';
import IconWhisper from 'assets/icon/IconWhisper';

export const TYPE_CALL = {
    OUT: 'outgoingcall',
    IN: 'incommingcall',
    SMART: 'smartview'
};

export const TYPE_OUT_GOING = {
    CALLING: 'calling',
    ACTIVE: 'active',
    BUSY: 'busy',
    REJECT: 'reject',
    END: 'end',
    TRANSFER: 'transferred',
    TRANSFERRING: 'transferring',
    PAUSED_EDIT: 'pausededit',
    PAUSED: 'paused',
    ACCEPTING: 'accepting',
    EMPTY: 'empty',
    NEXT_LEAD: 'next_lead',
    PREPARE: 'prepare',
    BARGER: 'barger'
};

export const TYPE_IN_COMMING = {
    BUSY: 'busy',
    FREE: 'free',
    RINGING: 'ringing',
    CALLING: 'calling',
    END: 'end',
    TRANSFER: 'transferred',
    TRANSFERRING: 'transferring',
    BARGER: 'barger'
};

export const TYPE_DEVICE_AUDIO = {
    INPUT: 'audioinput',
    OUTPUT: 'audiooutput',
    RINGING: 'audioringing'
};

export const TYPE_SOUND = {
    SPEAKER: 'speaker',
    RINGING: 'ringing'
};

export const DEVICE_ID_AUDIO_DEFAULT = 'default';
export const KEY_PHONE_SETTINGS = 'phone_settings';

export const TYPE_CALL_DISCONNECT = {
    COMPLETED: 'completed',
    NOANSWER: 'no-answer',
    CANCELED: 'canceled',
    BUSY: 'busy',
    FAILED: 'failed',
    VOICEMAIL_DROP: 'voicemail_drop'
};

export const TYPE_CALL_DISCONNECT_WITH_APP = {
    [TYPE_CALL_DISCONNECT.COMPLETED]: TYPE_OUT_GOING.END,
    [TYPE_CALL_DISCONNECT.NOANSWER]: TYPE_OUT_GOING.BUSY,
    [TYPE_CALL_DISCONNECT.CANCELED]: TYPE_OUT_GOING.END,
    [TYPE_CALL_DISCONNECT.BUSY]: TYPE_OUT_GOING.BUSY,
    [TYPE_CALL_DISCONNECT.FAILED]: TYPE_OUT_GOING.END
};

export const TITLE_END_CALL = {
    [TYPE_OUT_GOING.END]: 'call_ended',
    [TYPE_OUT_GOING.REJECT]: 'busy_or_reject',
    [TYPE_OUT_GOING.BUSY]: 'busy_or_reject',
    [TYPE_CALL_DISCONNECT.VOICEMAIL_DROP]: 'voicemail_dropped'
};

export const OPTIONS_ADDONS_VOIP_ONLY_OWNER = [ADDONS_VOIP_ACTIVE_USER, ADDONS_VOIP_AUTO_ATTENDANT];

export const BROWSER_DEVICES = {
    CHROME: 'chrome',
    SAFARI: 'safari',
    EDGE: 'edge',
    FIREFOX: 'firefox',
    OTHER: 'Other browser'
};

export const getVoiceBrowser = (browser) => {
    switch (browser) {
        case BROWSER_DEVICES.CHROME:
            return 'Google US English';
        case BROWSER_DEVICES.SAFARI:
            return 'Samantha';
        case BROWSER_DEVICES.EDGE:
            return 'Microsoft Zira - English (United States)';
        default:
            return '';
    }
};

export const VOIP_PLAN_BASIC = '1';
export const VOIP_PLAN_PRO = '1000';
export const VOIP_PLAN_GROWTH = '2000';

export const EVENT_MESSAGE_RECEIVED = {
    EVENT_OUT_OF_SERVICE: 'out-of-service',
    EVENT_PARTICIPANT: 'participant',
    EVENT_PARTICIPANT_JOIN: 'participant-join',
    EVENT_PARTICIPANT_LEAVE: 'participant-leave',
    EVENT_PARTICIPANT_MODIFY: 'participant-modify',
    EVENT_PARTICIPANT_MUTE: 'participant-mute',
    EVENT_PARTICIPANT_UNMUTE: 'participant-unmute'
};

export const VOIP_CONFIRM = {
    SWITCH_CALL: 'switch_call'
};

export const TYPE_ACTION_INVITE = {
    CALLER: 0,
    LISTEN: 1,
    WHISPER: 2,
    BARGE: 3
};

export const TEXT_ACTION_INVITE = {
    LISTEN: 'listen',
    WHISPER: 'whisper',
    BARGE: 'barge'
};

export const LIST_ACTION_INVITE = [
    {
        id: TYPE_ACTION_INVITE.LISTEN,
        icon: <IconMicrophone />,
        text: TEXT_ACTION_INVITE.LISTEN,
        tooltipText: 'no_one_will_hear_you'
    },
    {
        id: TYPE_ACTION_INVITE.WHISPER,
        text: TEXT_ACTION_INVITE.WHISPER,
        icon: <IconWhisper />,
        tooltipText: 'only_owner_will_hear_your'
    },
    {
        id: TYPE_ACTION_INVITE.BARGE,
        text: TEXT_ACTION_INVITE.BARGE,
        icon: <IconMicrophone isNormal />,
        tooltipText: 'everyone_will_hear_you'
    }
];

export const TYPE_PARTICIPANT_LOG = {
    [TYPE_ACTION_INVITE.CALLER]: 'caller',
    [TYPE_ACTION_INVITE.LISTEN]: 'listened',
    [TYPE_ACTION_INVITE.WHISPER]: 'whispered',
    [TYPE_ACTION_INVITE.BARGE]: 'barged'
};

export const LIST_EVENT_EXCLUDE_PARTICIPANT = [
    EVENT_MESSAGE_RECEIVED.EVENT_PARTICIPANT_MUTE,
    EVENT_MESSAGE_RECEIVED.EVENT_PARTICIPANT_UNMUTE
];

export const STATE_ACTION_PARTICIPANT = {
    [TYPE_ACTION_INVITE.LISTEN]: {
        isCoaching: false,
        isMute: true
    },
    [TYPE_ACTION_INVITE.WHISPER]: {
        isCoaching: true,
        isMute: false
    },
    [TYPE_ACTION_INVITE.BARGE]: {
        isCoaching: false,
        isMute: false
    }
};

export const getTypeCoachingVoip = ({ isCoaching, isMute }) => {
    switch (true) {
        case isCoaching:
            return TEXT_ACTION_INVITE.WHISPER;
        case isMute:
            return TEXT_ACTION_INVITE.LISTEN;
        default:
            return TEXT_ACTION_INVITE.BARGE;
    }
};

export const VOIP_PRESIGNED_TYPE = {
    MUSIC: 'voip_music',
    VOICEMAIL: 'voip_voicemail'
};

export const MESSAGE_ERROR_CODE_VOIP = {
    31402: 'msg_error_media_voip'
};

export const LIST_TYPE_CALL_ENDED = [TYPE_OUT_GOING.END, TYPE_OUT_GOING.BUSY, TYPE_CALL_DISCONNECT.VOICEMAIL_DROP];
export const LIST_RECHARGE_THRESHOLD = [10, 25, 50];
export const LIST_RECHARGE_AMOUNT = [100, 250, 500];
export const LIST_CLASS_DIVIDE = ['sm', 'md', 'lg'];
export const LINK_LEARN_MORE_VOIP =
    'https://intercom.help/gorilladesk/en/articles/8075419-make-and-receive-calls-inside-gorilladesk-using-voip';
