/* eslint-disable no-undef */
import { API_ENDPOINT, API_VERSION, LINK_SQUARE_DASHBOARD, LINK_STRIPE_DASHBOARD } from 'app/const/URL';
import { createUUID } from 'common/utils/StringUtils';
import { KEY_LOCAL_STORAGE_SQUARE, KEY_LOCAL_STORAGE_STRIPE } from '../Payments';
import { setLocalStorage } from 'common/utils/LocalStorageUtils';

// Google calendar sync
export const ADDONS_CALENDAR_SETTINGS = `${API_VERSION}addons/calendar/settings`;

// Templates
// 'templates/system/:id'
export const getTemplateSystem = (id) => `${API_VERSION}templates/system/${id}`;
export const getTemplateAutoMessage = (id) => `${API_VERSION}templates/automessages/${id}`;
export const getTemplateEmailInbox = (id) => `${API_VERSION}templates/emails/${id}`;
export const getTemplateBroadcast = (id) => `${API_VERSION}templates/broadcasts/${id}`;

// Settings
export const SETTING_UPDATE_EMAIL_SENDER = `${API_VERSION}settings/email/sender`;

export const updateColorSettingService = (serviceId) => {
    return `${API_VERSION}services/${serviceId}/color`;
};

export const GET_DETAIL_ACCOUNT = `${API_VERSION}account`;

export const GET_LIST_ACCOUNT_PLAN = `${API_VERSION}account/plans`;

export const GET_LIST_ACCOUNT_CARD = `${API_VERSION}account/cards`;

export const ACTIVE_PLAN_WITH_OLD_CARD = `${API_VERSION}account/activeOldCard`;

export const ACTIVE_PLAN_WITH_NEW_CARD = `${API_VERSION}account/active`;

export const ACTION_ACCOUNT_CARD = `${API_VERSION}account/card`;

export const ACTION_SET_CARD_DEFAULT = `${API_VERSION}account/default`;

export const GET_LIST_INVOICE_DATA = `${API_VERSION}account/invoices`;

export const GET_DETAIL_ACCOUNT_INVOICE = `${API_VERSION}account/invoice`;

export const GET_QUICKBOOK_DETAIL = `${API_VERSION}quickbooks`;

export const ADDON_CONNECT_QUICKBOOK = `${API_VERSION}quickbooks/connect`;

export const ADDON_DISCONNECT_QUICKBOOK = `${API_VERSION}quickbooks/disconnect`;

export const GET_QUICKBOOKS_LOG = `${API_VERSION}quickbooks/logs`;

export const GET_LIST_QUICKBOOKS_TAX = `${API_VERSION}quickbooks/tax`;

export const GET_LIST_QUICKBOOKS_ACCOUNT = `${API_VERSION}quickbooks/account`;

export const ACTIONN_UPDATE_QUICKBOOKS_ADDON = `${API_VERSION}quickbooks/update`;

export const ACTIONN_UPDATE_OPTION_QUICKBOOKS_ADDON = `${API_VERSION}quickbooks/option`;

export const ACTION_QUICKBOOKS_MAPPING = `${API_VERSION}quickbooks/mapping`;

export const actionQuickBooksMapping = (mappingId) => {
    return `${API_VERSION}quickbooks/mapping/${mappingId}`;
};

export const GET_ACCOUNT_DETAIL = `${API_VERSION}account`;
export const PORTAL_REQUEST_JOB = `${API_ENDPOINT}/portal/request/job`;
export const REQUEST_RESCHEDULES = `${API_ENDPOINT}/portal/request/reschedule`;
export const REQUEST_ESIGN_INVOICE = `${API_ENDPOINT}/portal/request/invoice`;
export const REQUEST_ESIGN_ESTIMATE = `${API_ENDPOINT}/portal/request/estimate`;
export const REQUEST_ESIGN_DOCUMENT = `${API_ENDPOINT}/portal/request/document`;
export const PORTAL_REQUEST_CONFIRM = `${API_ENDPOINT}/portal/request/confirm`;
export const PAYMENT_REQUEST = `${API_ENDPOINT}/portal/request/pay`;
export const PAYMENT_REQUEST_PAY = `${API_ENDPOINT}/portal/request/payment`;
export const SIGNATURE_INVOICE_ESTIMATE = `${API_ENDPOINT}/portal/request/signature`;
export const SIGNATURE_DOCUMENT = `${API_ENDPOINT}/portal/request/signatureDocument`;

export const RE_ASSIGN_SCHEDULE = `${API_VERSION}settings/schedules/reassign`;

export const GET_CLIENT_SECRET = `${API_VERSION}payments/clientSecret`;

export const updateToolTipSettings = (tooltipId) => {
    return `${API_VERSION}settings/tooltip/${tooltipId}`;
};

export const GET_SETTINGS_TEMPLATES = `${API_VERSION}settings/template`;
export const GET_SETTINGS_TEMPLATES_OPTIONS = `${API_VERSION}settings/template/options`;

export const GET_LIST_GROUP_SCHEDULES = `${API_VERSION}settings/schedules/groups`;
export const settingGroupSchedule = (groupId) => `${API_VERSION}settings/schedules/${groupId}/groups`;
export const EXPORT_INVOICE = `${API_VERSION}invoices/export`;
export const SEND_LATE_PAYMENT_INVOICE_EMAIL = `${API_VERSION}invoices/reminder/email`;
export const SEND_LATE_PAYMENT_INVOICE_SMS = `${API_VERSION}invoices/reminder/sms`;
export const SEND_STATEMENT_EMAIL = `${API_VERSION}statements/email`;

export const actionLocationMote = (locationId) => {
    return `${API_VERSION}locations/${locationId}/note`;
};

export const GET_LIST_TEAMMATE = `${API_VERSION}rating/service/users`;
export const GET_EXIST_CUSTOMER_BY_LOCATION = `${API_VERSION}locations/existed`;
export const ACTION_UPLOAD_IMAGE = `${API_VERSION}upload/image/photo`;

export const actionDeleteLocationPhoto = (locationId) => {
    return `${API_VERSION}locations/${locationId}/photo`;
};

export const SIGN_UP_VALIDATE_EMAIL = `${API_VERSION}signup/validate`;
export const DELETE_CUSTOMER_STRIPE_ID = `${API_VERSION}customers/card/account`;
export const changeBalanceTotal = (customer_id) => `${API_VERSION}customers/${customer_id}/balance`;
export const editCustomerAccount = (customer_id) => `${API_VERSION}customers/${customer_id}`;

export const sendMailStateMentCustomer = (idCustomer) => {
    return `${API_VERSION}statements/${idCustomer}/email`;
};

export const GET_TEMPLATE_EMAIL_STATEMENT = `${API_VERSION}templates/statement/email`;

export const GET_TEMPLATE_SMS_STATEMENT = `${API_VERSION}templates/statement/sms`;

export const sendSmsStateMentCustomer = (idCustomer) => {
    return `${API_VERSION}statements/${idCustomer}/sms`;
};

export const GET_REFETCH_TOKEN_GOOGLE_CALENDAR_SYNC = `${API_VERSION}addons/calendar/token`;
export const GET_TOKEN_GOOGLE_CALENDAR_SYNC = `${API_VERSION}addons/google/auth`;

export const DELETE_LOCATION_CUSTOMER = `${API_VERSION}customers/locations`;
export const searchAccountCustomerCard = (customerId) => `${API_VERSION}customers/${customerId}/card/search`;
export const connectStripeExisting = (customerId) => `${API_VERSION}customers/${customerId}/card/connect`;

export const customerEditPayment = (paymentId) => `${API_VERSION}payments/${paymentId}`;

export const SEARCH_INVOICE_DUPLICATE = `${API_VERSION}quickbooks/invoice/search`;

export const DELETE_INVOICE_DUPLICATE = `${API_VERSION}quickbooks/invoice/delete`;
export const GET_LIST_TEMPLATE_VARIABLES = `${API_VERSION}templates/variables`;

export const SAVE_PORTAL_LINK = `${API_VERSION}addons/subdomain`;
export const getLinkStripeConnect = (client_id, branch_id) => {
    const state = `v3_${createUUID()}_${branch_id}`;
    setLocalStorage(KEY_LOCAL_STORAGE_STRIPE, state);
    return `https://connect.stripe.com/oauth/authorize?response_type=code&amp;scope=read_write&amp;client_id=${client_id}&amp;redirect_uri=${process.env.REACT_APP_STRIPE_REDIRECT_URI}&amp;state=${state}`;
};

export const getLinkSquareConnect = (client_id, branch_id) => {
    const state = `v3_${createUUID()}_${branch_id}`;
    setLocalStorage(KEY_LOCAL_STORAGE_SQUARE, state);
    return `${process.env.REACT_APP_SQUARE_CONNECT_LINK}/oauth2/authorize?client_id=${client_id}&amp;scope=MERCHANT_PROFILE_READ PAYMENTS_READ SETTLEMENTS_READ BANK_ACCOUNTS_READ CUSTOMERS_READ ORDERS_READ CUSTOMERS_WRITE PAYMENTS_WRITE ORDERS_WRITE INVOICES_WRITE SUBSCRIPTIONS_WRITE ITEMS_WRITE SUBSCRIPTIONS_READ ITEMS_READ INVOICES_READ&amp;session=1&amp;state=${state}`;
};

export const urlOpenStripe = (accountId) => {
    return `https://dashboard.stripe.com/subscriptions/${accountId}`;
};
export const urlOpenSquare = (accountId) => {
    return `${LINK_SQUARE_DASHBOARD}/subscriptions/${accountId}`;
};

export const linkSquareDashboard = (account_customer_id) => {
    return `${LINK_SQUARE_DASHBOARD}/customers/directory/customer/${account_customer_id}`;
};

export const linkStripeDashboard = (account_customer_id, subscription_id) => {
    return `${LINK_STRIPE_DASHBOARD}/customers/${account_customer_id}/upcoming_invoice/${subscription_id}`;
};

export const ADDONS_TIME_WINDOW = `${API_VERSION}addons/timewindow`;
export const UPDATE_ADDONS_TIME_WINDOW_OPTIONS = `${API_VERSION}addons/timewindow/options`;
export const GET_LIST_TIME_WINDOW_TEMPLATE = `${API_VERSION}addons/timewindow/templates`;
export const updateTimeWindowJob = (jobId = '') => {
    return `${API_VERSION}jobs/${jobId}/timeWindow`;
};
export const GET_CUSTOMERS_SETTINGS = `${API_VERSION}customers/settings`;
// Add and delete sold by in job detail
export const updateSoldByJob = (job_id = '') => `${API_VERSION}jobs/${job_id}/soldby`;

export const UPDATE_ADDONS_COMMISSION_OPTIONS = `${API_VERSION}addons/commission/options`;
export const LIST_COMMISSION_ITEMS = `${API_VERSION}commissions/items`;
export const UPDATE_QUEST_LOG_OPTION = `${API_VERSION}questlogs/option`;

export const updateTagsSideBar = (customerId = '') => `${API_VERSION}customers/${customerId}/tags`;
export const updateTagsLocation = (customerId = '') => `${API_VERSION}locations/${customerId}/tags`;

// Timeoffs
export const TIMEOFF_API = `${API_VERSION}timeoffs`;
export const getTimeoffDetail = (eventId = '') => `${TIMEOFF_API}/${eventId}`;
// Events
export const EVENT_API = `${API_VERSION}events`;
export const getEventDetail = (eventId = '') => `${EVENT_API}/${eventId}`;
export const UN_DELETE_LOCATION = `${API_VERSION}locations/undelete`;

export const MOVE_JOB_FROM_MAP = `${API_VERSION}calendar/move`;
export const GET_MAP_MARKER = `${API_VERSION}jobs/map`;
export const getInvoicesSubscription = (customer_id) => `${API_VERSION}customers/${customer_id}/subscription/invoices`;

export const actionInvoiceNoteRepeat = (invoiceId) => `${API_VERSION}invoices/${invoiceId}/recurring`;
export const USER_PROFILE = `${API_VERSION}profile`;
export const cardLogs = (customerId, cardId) => `${API_VERSION}customers/${customerId}/cards/${cardId}/logs`;
export const cardUpdate = (customerId) => `${API_VERSION}customers/${customerId}/card`;

// Smart Views
export const SMART_VIEWS = `${API_VERSION}smartviews`;
export const SMART_VIEWS_ORDER = `${SMART_VIEWS}/orders`;
export const SMART_VIEWS_PREVIEW_EMAIL = `${SMART_VIEWS}/previewemail`;
export const SMART_VIEWS_SEND_EMAIL = `${SMART_VIEWS}/sendemail`;
export const PREVIEW_EMAIL_TEMPLATE = `${API_VERSION}templates/emails/preview`;
export const getSmartView = (id) => `${SMART_VIEWS}/${id}`;

// Opportunity
const BASE_CUSTOMER_API = `${API_VERSION}customer`;
export const customerOpportunity = (customerId, opportunityId = '') => {
    let url = `${BASE_CUSTOMER_API}/${customerId}/opportunity`;
    if (!!opportunityId) url += `/${opportunityId}`;
    return url;
};

//pipelines
export const SETTING_PIPELINES = `${API_VERSION}addons/opportunity`;
export const ADDONS_PIPELINES = `${API_VERSION}addons/opportunity/settings`;
export const actionCreatePipelines = (idGroup) => `${SETTING_PIPELINES}/${idGroup}`;
export const actionUpdatePipelines = (idGroup, idPipeline) => `${SETTING_PIPELINES}/${idGroup}/pipeline/${idPipeline}`;
export const actionAlterPipelines = (idGroup, idPipeline) => `${SETTING_PIPELINES}/${idGroup}/alter/${idPipeline}`;
export const actionCheckPipelines = (idGroup, idPipeline) => `${SETTING_PIPELINES}/${idGroup}/check/${idPipeline}`;
export const actionOrdersPipelines = (idGroup, idPipeline) => `${SETTING_PIPELINES}/${idGroup}/orders/${idPipeline}`;

export const REPORT_OPPORTUNITY_PIPELINE = `${API_VERSION}reports/opportunity/pipeline`;
export const GET_BRANCHES = `${API_VERSION}branches`;
export const REPORT_OPPORTUNITY_SALE_FUNNEL = `${API_VERSION}reports/opportunity/funnel`;
export const REPORT_STATUS_CHANGES = `${API_VERSION}reports/opportunity/status`;
export const ADDONS_OPPORTUNITIES_PERMISSIONS = `${API_VERSION}addons/opportunity/permissions`;
export const actionUpdatePermissionPipelines = (userId) => `${API_VERSION}addons/opportunity/permissions/${userId}`;

// ACH
export const addBankAccount = (customerId) => `${API_VERSION}customers/${customerId}/bank`;
export const verifyBankAccount = (customerId, bankId) => `${API_VERSION}customers/${customerId}/card/${bankId}/verify`;
export const emailInvitationBank = (customerId) => `${API_VERSION}customers/${customerId}/bank/email`;
export const smsInvitationBank = (customerId) => `${API_VERSION}customers/${customerId}/bank/sms`;

// Tracker - tracking time
export const WORK_LOGS = `${API_VERSION}worklogs`;
export const workLogsUpdate = (workLogId) => `${WORK_LOGS}/${workLogId}`;
export const ADDONS_WORK_LOG_USER = `${API_VERSION}addons/worklog` + '/users';
export const workLogUserUpdate = (id) => ADDONS_WORK_LOG_USER + `/${id}`;
export const REPORT_WORK_LOG = `${API_VERSION}reports/worklogs`;

// Map - tracking geos location
export const MAPS_GEO_TRACKING = `${API_VERSION}map/job/geo`;

// Custom Fields
export const CUSTOM_FIELDS = `${API_VERSION}addons/customfield/fields`;
export const CUSTOM_FIELDS_ORDER = `${CUSTOM_FIELDS}/orders`;
export const LIST_USER_PERMISSION_CUSTOM_FIELDS = `${API_VERSION}addons/customfield/permissions`;
export const updatePermissionCustomFields = (userId) => `${LIST_USER_PERMISSION_CUSTOM_FIELDS}/${userId}`;
export const customerCustomFields = (id) => `${API_VERSION}customers/${id}/customfields`;

// SMS template
export const GET_TEMPLATES_SMS = `${API_VERSION}templates/sms`;
export const updateTemplateSMS = (id) => `${API_VERSION}templates/sms/${id}`;

//override
export const GLOBAL_OVERRIDE_SETTING = `${API_VERSION}invoices/override`;

// Custom Job Status
export const JOB_STATUS_END_POINT = `${API_VERSION}addons/jobstatus/statuses`;
export const updateJobStatus = (id) => `${API_VERSION}addons/jobstatus/statuses/${id}`;
export const JOB_STATUS_PERMISSION_END_POINT = `${API_VERSION}addons/jobstatus/permissions`;
export const updateJobStatusPermission = (id) => `${API_VERSION}addons/jobstatus/permissions/${id}`;
export const JOB_STATUS_ORDERS_END_POINT = `${JOB_STATUS_END_POINT}/orders`;

// referral
export const GET_REFERRAL_DETAIL = `${API_VERSION}referral`;
export const GET_LIST_REFERRAL_ACCOUNT = `${GET_REFERRAL_DETAIL}/accounts`;
// Estimates template
export const GET_TEMPLATES_ESTIMATES = `${API_VERSION}templates/estimate`;
export const actionTemplateEstimates = (id) => `${API_VERSION}templates/estimate/${id}`;

export const actionDeleteSms = (id) => `${API_VERSION}sms/${id}`;
export const actionSendNotifyTechnician = (id) => `${API_VERSION}job/${id}/notifyTech`;

// Magnets
export const getJobsMagnet = (jobId) => `${API_VERSION}job/${jobId}/magnet`;
export const CALENDAR_JOBS_MAGNETS = `${API_VERSION}job/magnet`;
export const JOBS_BATCH_MOVE = `${API_VERSION}job/batchmove`;
export const GET_JOB_BEST_TIME = `${API_VERSION}job/besttime`;
// Device Tracking
export const REPORT_DEVICE_TRACKING = `${API_VERSION}reports/devices/tracking`;
// AI chatbot
export const API_CHATBOT = `${API_VERSION}addons/aiagents`;
export const GET_SETTING_CHATBOT = `${API_CHATBOT}/settings`;
export const GET_CHATBOT_REPORT = `${API_CHATBOT}/conversations`;
export const ALTER_CHATBOT_REPORT = `${GET_CHATBOT_REPORT}/alter`;
export const GET_LIST_CONVERSATION_TAGS = `${GET_CHATBOT_REPORT}/tags`;
export const updateConversationTags = (id) => `${GET_CHATBOT_REPORT}/${id}/tags`;
export const GET_CHATBOT_TRAIN_AI = `${API_CHATBOT}/template/questions`;
export const getChatbotOccurrences = (id) => `${GET_CHATBOT_TRAIN_AI}/${id}/occurrences`;
export const getChatbotAnswers = (id) => `${GET_CHATBOT_TRAIN_AI}/${id}/answers`;
export const getChatbotTrainAISource = (id) => `${GET_CHATBOT_TRAIN_AI}/${id}/sources`;
export const TEST_SMS_CHATBOT = `${API_CHATBOT}/sms/messages`;
export const CHATBOT_CUSTOM_ANSWER = `${API_CHATBOT}/customanswers`;
export const CHATBOT_CONTENT = `${API_CHATBOT}/content`;
export const batchActionChatbot = (type) => `${API_CHATBOT}/${type}/alter`;
//2FA
export const GET_AUTH_PROFILE = `${API_VERSION}user/2fa`;
export const setDefaultAuth = (id) => `${GET_AUTH_PROFILE}/default/${id}`;
export const updateStatus2FA = (id) => `${GET_AUTH_PROFILE}/status/${id}`;
export const GET_NEW_AUTH_CODE = `${GET_AUTH_PROFILE}/authcode`;
export const GET_NEW_MAIL_CODE = `${GET_AUTH_PROFILE}/email`;
export const GET_NEW_BACKUP_CODE = `${GET_AUTH_PROFILE}/backupcode`;
export const CREATE_AUTH_METHOD = `${GET_AUTH_PROFILE}/method`;
export const actionAuthMethod = (id) => `${GET_AUTH_PROFILE}/${id}`;
export const VERIFY_CODE = `${GET_AUTH_PROFILE}/verify`;
export const SEND_MAIL = `${VERIFY_CODE}/sendemail`;
export const CHANGE_PASSWORD_PROFILE = `${API_VERSION}profile/changepassword`;
export const ACCOUNT_DETAIL_CHATBOT = `${API_CHATBOT}/account`;
// Drive time
export const CALENDAR_JOBS_DRIVE_TIME = `${API_VERSION}job/drivetime`;

// Holiday
export const LIST_HOLIDAYS = `${API_VERSION}calendar/holiday`;
