import loadable from '@loadable/component';
import { React, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ButtonSave from 'app/components/button/ButtonSave';
import GDModalWarning from 'app/components/modal/ModalWarning';
import { ADDONS_SERVICE_PLAN, SETTINGS_SERVICE_PLAN_ADD, SETTINGS_SERVICE_PLAN_EDIT } from 'app/config/routes';
import { SETTING_SERVICE_PLAN_TRASH, SETTINGS_GET_LIST_SERVICE_PLAN } from 'app/const/Api';
import { COMMON, LIST_STATUS } from 'app/const/App';
import { addBranchPath } from 'app/const/Branch';
import { ACCESS_SETTINGS_TAB } from 'app/const/Permissions';
import { reducer } from 'app/const/Reducer';
import { SERVICE_PLAN } from 'app/const/setting/SettingPageName';
import {
    getGridColumnsSettingServicePlan,
    LIST_BUTTONS,
    MESSAGES_TYPE_WARNING,
    STATE_TAB_SERVICE_PLAN,
    TAB
} from 'app/const/setting/SettingServiceTable';
import { handleAbortController } from 'app/modules/customer/utils';
import ErrorPage from 'app/modules/error';
import { getLocalStorageSettingPage, updateLocalStorageSettingPage } from 'app/modules/settings/utils/localStorage';
import { clientQuery } from 'common/utils/ApiUtils';
import { handleActionHeaderReport } from 'common/utils/GridViewUtils';
import { checkAccessFail, isActiveFeature } from 'common/utils/PermissionUtils';
import ActionHeader from '../service/components/ActionHeader';

const SettingTabsFilter = loadable(() => import('app/modules/settings/components/SettingTabsFilter'));
const GdGridView = loadable(() => import('app/components/grid/GdGridView'));
const IconPlus = loadable(() => import('assets/icon/IconPlus'));
const AlertCustomer = loadable(() => import('app/modules/customer/components/AlertCustomer'));
const MainHeaderSettings = loadable(() => import('app/modules/settings/components/MainHeaderSettings'));

const SettingsServicePlan = () => {
    const { t } = useTranslation(['setting']);
    const servicePlanAddon = useSelector(({ auth }) => auth.user.settings.addons.service_plan);
    const history = useHistory();
    const defaultCheckedItems = { is_check_all: false, ids: [] };
    const [state, dispatchState] = useReducer(reducer, {
        data: [],
        isLoading: false,
        checkedItems: defaultCheckedItems,
        listTab: TAB.LIST,
        currentTab: getLocalStorageSettingPage({
            namePage: SERVICE_PLAN,
            key: COMMON.CURRENT_TAB,
            defaultCurrentTab: TAB.DEFAULT_SELECTED.ACTIVE
        }),
        typeWarning: 0,
        cursor: '',
        showMore: false,
        isLoadMore: false
    });
    const {
        data: finalData,
        currentTab: finalCurrentTab,
        checkedItems: finalCheckedItems = {},
        isLoading: finalIsLoading = false,
        listTab: finalListTab,
        typeWarning: finalTypeWarning,
        cursor: finalCursor,
        showMore: finalShowMore,
        isLoadMore: finalIsLoadMore
    } = state;
    const { ids: listIdChecked = [] } = finalCheckedItems;
    const totalSelected = listIdChecked.length;

    const refWarning = useRef(null);
    const refAlert = useRef(true);
    const refButtonSave = useRef(null);
    const abortController = useRef();
    const paramsSetting = getLocalStorageSettingPage({ namePage: SERVICE_PLAN, key: COMMON.PARAMS });
    const havePermission = !!servicePlanAddon && isActiveFeature(ADDONS_SERVICE_PLAN);

    useEffect(() => {
        if (!havePermission) return;
        _getListServicePlanSetting({});

        return () => {
            handleAbortController(abortController);
        };
    }, []);

    const _handleChangeTab = (tabId) => {
        _getListServicePlanSetting({ tabId, isClearCursor: true });
    };

    const _getListServicePlanSetting = ({
        params = {},
        tabId = finalCurrentTab,
        shouldLoading = true,
        shouldLoadMore = false,
        isClearCursor = false
    }) => {
        handleAbortController(abortController);
        abortController.current = new AbortController();
        const finalParams = { ...paramsSetting, ...params, state: STATE_TAB_SERVICE_PLAN[tabId] };
        updateLocalStorageSettingPage({ namePage: SERVICE_PLAN, value: { params: finalParams, current_tab: tabId } });
        dispatchState((prev) => ({
            ...prev,
            isLoading: shouldLoading,
            currentTab: tabId,
            isLoadMore: shouldLoadMore,
            cursor: isClearCursor ? '' : prev.cursor,
            showMore: isClearCursor ? false : prev.showMore,
            checkedItems: !shouldLoadMore ? defaultCheckedItems : prev.checkedItems
        }));

        const _handleSuccess = ({ data = [], show_more = false, cursor = '' }) => {
            dispatchState((prev) => {
                const { checkedItems: prevCheckedItems, data: prevData } = prev;
                const newData = prev.isLoadMore ? [...prevData, ...data] : data;
                return {
                    ...prev,
                    data: newData,
                    isLoading: false,
                    currentTab: getLocalStorageSettingPage({ namePage: SERVICE_PLAN, key: COMMON.CURRENT_TAB }),
                    checkedItems: {
                        ...prevCheckedItems,
                        ids: prevCheckedItems.is_check_all ? (newData || []).map((e) => e.id) : prevCheckedItems.ids
                    },
                    cursor,
                    showMore: show_more,
                    isLoadMore: false
                };
            });
        };
        const _handleFail = (error = {}) => {
            const { isAborted = false } = error;
            if (isAborted) return;
            dispatchState((prev) => ({ ...prev, data: [], isLoading: false }));
            checkAccessFail(error, ACCESS_SETTINGS_TAB);
        };
        delete finalParams?.current_tab;
        if (!isClearCursor && finalCursor) finalParams['cursor'] = finalCursor;
        clientQuery(
            SETTINGS_GET_LIST_SERVICE_PLAN,
            { data: finalParams, method: 'GET', abortController: abortController.current },
            _handleSuccess,
            _handleFail
        );
    };

    const _onChangeStateCheckedItems = (checkedItems = {}) => {
        dispatchState((prev) => ({ ...prev, checkedItems }));
    };

    const _handleClickButtonAction = (value) => {
        if (totalSelected) {
            dispatchState((prev) => ({ ...prev, typeWarning: value }));
            const action = MESSAGES_TYPE_WARNING[value];
            refWarning.current._open({
                title: action,
                description: t('setting:are_you_sure_you_want_to', {
                    action: action.toLowerCase(),
                    number: totalSelected,
                    prefix: totalSelected > 1 ? 's' : ''
                })
            });
        }
    };

    const _handleAddPlan = () => {
        history.push(addBranchPath(SETTINGS_SERVICE_PLAN_ADD));
    };

    const _handleClickItem = (item) => {
        history.push(addBranchPath(SETTINGS_SERVICE_PLAN_EDIT.replace(':id', item.id)));
    };

    const _handleActionHeader = ({ actionType, columnsTarget, currentValue }) => {
        handleActionHeaderReport({
            actionType: actionType,
            reportType: SERVICE_PLAN,
            columnsTarget,
            currentValue,
            paramsReport: getLocalStorageSettingPage({ namePage: SERVICE_PLAN, key: COMMON.PARAMS }),
            callBack: (params) => _getListServicePlanSetting({ params, isClearCursor: true }),
            quickResponse: true
        });
    };

    const _handleCloseWarning = () => {
        refWarning.current?._close();
        _removeLoading();
    };

    const _removeLoading = () => {
        refButtonSave.current?.removeLoading();
    };

    const _handleConfirm = () => {
        const _handleSuccess = () => {
            _getListServicePlanSetting({ isClearCursor: true });
            _handleCloseWarning();
            refAlert.current.showStatusBar({
                id: 'alter_service_plan_successfully',
                message: t('setting:message_alter_successfully', {
                    number: totalSelected,
                    name: MESSAGES_TYPE_WARNING[finalTypeWarning].toLowerCase(),
                    prefix: totalSelected > 1 ? 's' : ''
                }),
                type: LIST_STATUS.SUCCESS
            });
        };
        const _handleFail = ({ message }) => {
            refWarning.current?._setStatusAlert(message);
            _removeLoading();
            dispatchState((prev) => ({ ...prev, isLoading: false }));
        };
        clientQuery(
            SETTING_SERVICE_PLAN_TRASH,
            { data: { ids: listIdChecked, type: finalTypeWarning }, method: 'PUT' },
            _handleSuccess,
            _handleFail
        );
    };

    const _handleScrollToEnd = () => {
        if (!finalIsLoadMore && finalShowMore) {
            _getListServicePlanSetting({ shouldLoadMore: true, shouldLoading: false });
        }
    };

    const FooterWarning = () => {
        return (
            <div className="footer-modal footer-hasbtn btn-close">
                <span className="v2-btn-default --transparent" onClick={_handleCloseWarning}>
                    {t('cancel')}
                </span>
                <ButtonSave
                    ref={refButtonSave}
                    title={t('common:confirm')}
                    className="v2-btn-main"
                    onSave={_handleConfirm}
                />
            </div>
        );
    };

    if (!havePermission) return <ErrorPage errorMessage={t('auth:no_permission_access')} />;

    return (
        <>
            <MainHeaderSettings
                contentRight={() => (
                    <div onClick={_handleAddPlan} className="v2-btn-main has-icon svg-white header-items">
                        <IconPlus />
                        {t('setting:add_plan')}
                    </div>
                )}
                contentLeft={() => (
                    <SettingTabsFilter list={finalListTab} tabActive={finalCurrentTab} onChange={_handleChangeTab} />
                )}
            />
            <div className="wrapper-columns">
                <div className="container-print has-tab contents-pages maintables-page">
                    <AlertCustomer ref={refAlert} />
                    <ActionHeader
                        totalSelected={totalSelected}
                        listTabAction={LIST_BUTTONS[finalCurrentTab]}
                        checkedItems={finalCheckedItems}
                        isShowPermanentlyDelete={false}
                        handleClickButtonAction={_handleClickButtonAction}
                    />
                    <div className="tab-contents box-auto">
                        <div className="tab-conts tab-content-active">
                            <GdGridView
                                isLoading={finalIsLoading}
                                isHasDropdown
                                content={finalData}
                                classTable="tables scrolls-x has-checkbox --service"
                                fileTranslation={'setting'}
                                checkedItems={finalCheckedItems}
                                showCheckBox
                                {...getGridColumnsSettingServicePlan(paramsSetting?.order, finalCurrentTab)}
                                isScroll
                                isLoadmore={finalIsLoadMore}
                                onClickItem={_handleClickItem}
                                onChangeStateCheckedItems={_onChangeStateCheckedItems}
                                handleClickHeader={_handleActionHeader}
                                onScrollToEnd={_handleScrollToEnd}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <GDModalWarning ref={refWarning} isLargeContent={false} footer={<FooterWarning />} />
        </>
    );
};

export default SettingsServicePlan;
